import { CombinedState } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import {
  IChallengeRootState,
  IFeatureState,
  INotificationState,
  ISettingsState,
  IUserState,
} from '@estendio/presentpal-store';
import { IState } from '@estendio/presentpal-store';
import { AuthenticationActions } from '@estendio/presentpal-store';
import { ActionTypes } from '@estendio/presentpal-store';
import { IStatusState } from '@estendio/presentpal-store';
import { createClientStore } from '@estendio/presentpal-store';

//export const storePersist = persistStore(store);
export const { store, storePersist } = createClientStore({
  storage: storage as any,
});

export type IStore = typeof store;
export type IStoreDispatch = typeof store.dispatch;
export type IStoreState = CombinedState<{
  user: IUserState;
  decks: IState;
  status: IStatusState;
  settings: ISettingsState;
  features: IFeatureState;
  notifications: INotificationState;
  challenges: IChallengeRootState;
}>;

export const thunkDispatch = store.dispatch as ThunkDispatch<
  IStoreState,
  any,
  AuthenticationActions | ActionTypes
>;

export type IThunkDispatch = typeof thunkDispatch;

export const useTypedSelector: TypedUseSelectorHook<IStoreState> = useSelector;

export default store;
