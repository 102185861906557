import { IAwsClient } from '@estendio/presentpal-auth';
import { IAwsDeck } from '@estendio/presentpal-auth/dist/cloudDeckStorage/types';
import {
  getTruncatedTitle,
  getFinalTitleValue,
  UNTITLED_PLACEHOLDER,
} from '../helpers/InputHelper';
import {
  extractDeck,
  extractFlashCard,
  extractBullet,
  splitAllFlashcards,
  actionFetchAll,
  actionReceivedDecks,
  actionSetDecksLoading,
  actionUpdateDeck,
  actionDeleteDeck,
  actionUpdateFlashCard,
  actionUpdateBullet,
  actionCreateDeck,
  actionMarkPendingRemote,
  actionLinkPendingRemote,
  actionCreateFlashCard,
  actionCreateBullet,
  actionDeleteFlashCard,
  actionDeleteBullet,
  actionChangeFlashCardIndex,
  actionChangeBulletIndex,
  actionResolvePendingRemote,
  actionSentDeckChanges,
  actionFetchDeck,
  actionInsertFlashcardAtIndex,
  convertLocalDeckToAwsDeck,
  shouldFetchAll,
  shouldFetchDeck,
  actionResetAllStatus,
  actionStoreStatus,
  IThunkDispatch,
  IStoreDispatch,
  IStoreState,
  IBullet,
  IDeck,
  IFlashCard,
  IHint,
  HintType,
  ImageHint,
  TextHint,
} from '@estendio/presentpal-store';
import { SlideInfo } from '../services/OfficeService/OfficeService';
import {
  generateId,
  generateIdWithStandardGenerator,
  UNTITLED_FLASHCARD_PLACEHOLDER,
} from './helpers';
import {
  IMessageManager,
  IMessage,
  AllDeckRepoCommands,
  
  ICommandAction,
  ITarget,
  ICreateDeckCommand,
  ICreateFlashcardCommand,
  ICreateBulletCommand,
  IUpdateDeckCommand,
  IUpdateFlashcardCommand,
  IUpdateBulletCommand,
  IDeleteBulletCommand,
  IDeleteFlashcardCommand,
  IDeleteDeckCommand,
  IMoveFlashcardByOneIndexCommand,
  IMoveBulletByOneIndexCommand,
} from '@estendio/presentpal-rdm';
import handleChangesInDeckRepo from './realtimeDataManagement/deck/subscriptionHandlers';

export interface IDeckRepository {
  fetchDeck(deckId: string): Promise<void>;
  fetchDecks(forceUpdate?: boolean): Promise<void>;
  deleteDeck(deckId: string, isClone?: boolean): Promise<IDeck | null>;
  createDeck(title: string): Promise<IDeck>;
  updateDeck(deckId: string, deckChanges: Partial<IDeck>): Promise<IDeck>;
  updateFlashCard(
    deckId: string,
    flashcardId: string,
    flashcardChanges: Partial<IFlashCard>,
  ): Promise<IFlashCard>;
  updateBullet(
    deckId: string,
    flashcardId: string,
    bulletId: string,
    bulletChanges: Partial<IBullet>,
  ): Promise<IBullet>;
  createFlashCard(
    deckId: string,
    title: string,
    atIndex?: number,
  ): Promise<IFlashCard>;
  createFlashCardsInBulk(
    deckId: string,
    flashcardsNumber: number,
  ): Promise<IDeck>;
  createBullet(
    deckId: string,
    flashcardId: string,
    content: string,
  ): Promise<IBullet>;
  deleteFlashCard(
    deckId: string,
    flashcardId: string,
    isClone?: boolean,
  ): Promise<IFlashCard | null>;
  deleteBullet(
    deckId: string,
    flashcardId: string,
    bulletId: string,
    isClone?: boolean,
  ): Promise<IBullet | null>;
  uploadWebFile(file: File): Promise<string | null>;
  signImage(hint: IHint | null): Promise<string | null>;
  moveFlashCard(
    deckId: string,
    flashcardId: string,
    indexChange: number,
    isClone?: boolean,
  ): Promise<IDeck | null>;
  moveBullet(
    deckId: string,
    flashcardId: string,
    bulletId: string,
    indexChange: number,
    isClone?: boolean,
  ): Promise<IBullet | null>;
  resolvePendingRemoteLinks(): Promise<void>;
  sendChangedDecks(): Promise<boolean>;
  extractOfflineDeckChangeIds(state: IStoreState): string[];
  duplicateDeck(
    originalDeckId: string,
    newTitle?: string,
    markAsClone?: boolean,
  ): Promise<IDeck>;
  updateFlashcardsToMatchSlides(
    deckId: string,
    slidesInfo: SlideInfo[],
  ): Promise<IDeck>;
  setMessageManager(messageManager: IMessageManager): void;
  myMessageManager: IMessageManager | undefined;
}

export default class DeckRepository implements IDeckRepository {
  private myClient: IAwsClient;
  private thunkDispatch: IThunkDispatch;
  myMessageManager: IMessageManager | undefined;

  constructor(awsClient: IAwsClient, thunkDispatch: IThunkDispatch) {
    this.myClient = awsClient;
    this.thunkDispatch = thunkDispatch;
    this.myMessageManager = undefined;
  }

  setMessageManager(messageManager: IMessageManager) {
    this.myMessageManager = messageManager;
    this.subscribeToDeckChanges();
  }

  private subscribeToDeckChanges() {
    const messageHandler = (message: IMessage<AllDeckRepoCommands>) =>
      handleChangesInDeckRepo(message, this.thunkDispatch);
    this.myMessageManager?.onDeckChange(messageHandler);
  }

  async signImage(hint: IHint | null): Promise<string | null> {
    let result = null;

    if (hint && hint.type === HintType.Image && hint.content) {
      try {
        const signurl = await this.myClient.signS3Url(hint.content);
        result = signurl;
      } catch (e) {}
    }

    return result;
  }

  async resolvePendingRemoteLinks() {
    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        const state = getState();

        const linkedDecks = Object.keys(state.decks.pendingRemoteIds).filter(
          localId => typeof state.decks.pendingRemoteIds[localId] === 'string',
        );

        dispatch(actionResolvePendingRemote(linkedDecks));
      },
    );
  }

  private async createPendingLocalDeck(localId: string) {
    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        try {
          const state = getState();
          const localDeck = state.decks.decks.byId[localId];
          const response = await this.myClient.createDeck(localDeck.title);
          dispatch(actionLinkPendingRemote(localId, response.id));
          return response.id;
        } catch (e) {
          console.error(
            'Error creating a remote deck from existing local deck',
            e,
          );
          return Promise.reject('Unable to create the deck');
        }
      },
    );
  }

  async sendChangedDecks(): Promise<boolean> {
    return await this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        // This has been added to get offline only decks
        // It isnt returning false in the catch so that it should continue to work through if it fails
        try {
          const state = getState();
          const localIds = this.extractOfflineOnlyDecks(state);

          await Promise.all(
            localIds.map(async deckId => {
              await this.createPendingLocalDeck(deckId);
            }),
          );
        } catch (e) {
          console.error('Unable to send local only decks', e);
        }
        try {
          const state = getState();
          const changedIds = this.extractOfflineDeckChangeIds(state);

          await Promise.all(
            changedIds.map(async deckId => {
              await this.handleDeckUpdate(state, deckId, dispatch);
              dispatch(actionSentDeckChanges(deckId, Date.now()));
            }),
          );
        } catch (e) {
          console.log('Failed to save changes on sending deck change', e);

          return false;
        }
        return true;
      },
    );
  }

  extractOfflineDeckChangeIds(state: IStoreState) {
    const decks = state.decks.decks.byId;

    return state.decks.decks.allIds.filter(
      deckId =>
        decks[deckId] && decks[deckId].lastUpdated > decks[deckId].modified,
    );
  }

  extractOfflineOnlyDecks(state: IStoreState) {
    return Object.keys(state.decks.pendingRemoteIds).filter(
      localId => state.decks.pendingRemoteIds[localId] === null,
    );
  }

  async fetchDeck(deckId: string) {
    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        const deck = getState().decks.decks.byId[deckId];

        if (shouldFetchDeck(deck)) {
          dispatch(actionFetchDeck(deckId));
          try {
            const fetched = await this.myClient.getDeck(deckId);
            dispatch(actionReceivedDecks([fetched]));
          } catch (e) {
            // TODO add trucking with Amplitude
            dispatch(actionSetDecksLoading(false));
            console.error('Failed to fetch deck', deckId, e);
            return Promise.reject('Failed to fetch deck');
          }
        }
      },
    );
  }

  async fetchDecks(forceUpdate: boolean = false) {
    await this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        if (forceUpdate || shouldFetchAll(getState().decks)) {
          dispatch(actionFetchAll()); //sets loading to true
          try {
            const decks = await this.myClient.getDecks();
            dispatch(actionReceivedDecks(decks));
            dispatch(actionResetAllStatus());
          } catch (e) {
            // TODO add trucking with Amplitude
            dispatch(actionSetDecksLoading(false));
            console.error('Failed to fetch decks in fetchDecks', e);
            return Promise.reject(
              'Failed to fetch decks. Please check your internet connection',
            );
          }
        }
      },
    );
  }

  async uploadWebFile(file: File): Promise<string | null> {
    let result = null;
    try {
      result = await this.myClient.uploadWebImage(file);
    } catch (err) {
      console.error('failed to upload image', err);
    }
    return result;
  }

  async updateDeck(
    deckId: string,
    deckChanges: Partial<IDeck>,
  ): Promise<IDeck> {
    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        try {
          const state = getState();

          const oldDeck = extractDeck(deckId, state.decks, true);

          if (!oldDeck) {
            return Promise.reject("Cannot update a deck that doesn't exist");
          }

          const newDeck: IDeck = {
            ...oldDeck,
            ...deckChanges,
          };


          //Use the newDeck id instead of the deckId as the deckId might got resolved to remote id and that is reflected during extraction of the deck
          const newDeckId = newDeck.id;
          dispatch(actionUpdateDeck(newDeck.id, newDeck));

          const deckState = getState();

          await this.handleDeckUpdate(deckState, newDeckId, dispatch);

          const command: IUpdateDeckCommand = {
            action: ICommandAction.Update,
            targetId: newDeckId,
            payload: deckChanges,
          };
          this.sendChanges(newDeckId, deckState, ITarget.Deck, command);

          return newDeck;
        } catch (e) {
          console.error('Error updating deck', e);
          return Promise.reject('Unable to update the deck');
        }
      },
    );
  }

  async deleteDeck(deckId: string): Promise<IDeck | null> {
    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        try {
          let state = getState();

          const deck = extractDeck(deckId, state.decks);

          dispatch(actionDeleteDeck(deckId));

          state = getState();

          await this.handleDeckUpdate(state, deckId, dispatch);

          const command: IDeleteDeckCommand = {
            action: ICommandAction.Delete,
            targetId: deckId,
          };
          this.sendChanges(deckId, state, ITarget.Deck, command);

          return deck;
        } catch (e) {
          console.error('Error deleting deck', e);
          return Promise.reject('Unable to delete the deck');
        }
      },
    );
  }

  async updateFlashCard(
    deckId: string,
    flashcardId: string,
    flashcardChanges: Partial<IFlashCard>,
  ): Promise<IFlashCard> {
    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        try {
          let state = getState();

          const flashcard = extractFlashCard(flashcardId, state.decks);

          if (!flashcard) {
            return Promise.reject(
              "Cannot update a flashcard that doesn't exist",
            );
          }

          const newFlashCard: IFlashCard = {
            ...flashcard,
            ...flashcardChanges,
          };

          dispatch(
            actionUpdateFlashCard(deckId, flashcardId, flashcardChanges),
          );

          state = getState();

          await this.handleDeckUpdate(state, deckId, dispatch);

          const command: IUpdateFlashcardCommand = {
            action: ICommandAction.Update,
            targetId: flashcardId,
            payload: flashcardChanges,
            deckId,
          };
          this.sendChanges(deckId, state, ITarget.Flashcard, command);

          return newFlashCard;
        } catch (e) {
          console.error('Error updating flashcard', e);
          return Promise.reject('Unable to update the flashcard');
        }
      },
    );
  }

  async updateBullet(
    deckId: string,
    flashcardId: string,
    bulletId: string,
    bulletChanges: Partial<IBullet>,
  ): Promise<IBullet> {
    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        try {
          let state = getState();

          const bullet = extractBullet(bulletId, state.decks);

          if (!bullet) {
            return Promise.reject("Cannot update a bullet that doesn't exist");
          }

          dispatch(
            actionUpdateBullet(deckId, flashcardId, bulletId, bulletChanges),
          );
          state = getState();
          const newBullet: IBullet = state.decks.bullets.byId[bulletId];

          await this.handleDeckUpdate(state, deckId, dispatch);

          const command: IUpdateBulletCommand = {
            action: ICommandAction.Update,
            targetId: bulletId,
            payload: bulletChanges,
            flashcardId,
            deckId,
          };
          this.sendChanges(deckId, state, ITarget.Bullet, command);

          return newBullet;
        } catch (e) {
          console.error('Error updating bullet', e);
          return Promise.reject('Unable to update the bullet');
        }
      },
    );
  }

  async handleDeckUpdate(
    state: IStoreState,
    deckId: string,
    dispatch: IStoreDispatch,
  ) {
    let actualDeckId = deckId;
    let remoteExists = false;
    const pendingRemoteId = state.decks.pendingRemoteIds[deckId];
    if (typeof pendingRemoteId !== 'undefined' && pendingRemoteId !== null) {
      // deck is being worked on locally and a switch is pending
      actualDeckId = pendingRemoteId;
    }

    if (pendingRemoteId || typeof pendingRemoteId === 'undefined') {
      remoteExists = true;
    }

    const deckChanges = extractDeck(deckId, state.decks, true);

    if (!deckChanges) {
      return null;
    }
    if (remoteExists && !this.isClone(deckChanges)) {
      // swap the ids over to the expected remote id before sending
      const deckToSend = convertLocalDeckToAwsDeck({
        ...deckChanges,
        id: actualDeckId,
      });

      this.manageRemoteDeckUpdate(deckToSend, dispatch);
    }
    return deckChanges;
  }

  async manageRemoteDeckUpdate(deckToSend: IAwsDeck, dispatch: IStoreDispatch) {
    dispatch(actionStoreStatus('sending', deckToSend.id));
    try {
      this.myClient.updateDeck(deckToSend).then(
        () => {
          dispatch(actionStoreStatus('sent', deckToSend.id));
        },
        err => {
          console.error(' promise rejected in manageRemoteDeckUpdate', err);
          dispatch(actionStoreStatus('failed', deckToSend.id));
        },
      );
    } catch (err) {
      console.error('Failed to send changes in manageRemoteDeckUpdate', err);
      dispatch(actionStoreStatus('failed', deckToSend.id));
    }
  }

  isClone(deck: IDeck): boolean {
    return !!deck.originalId;
  }

  async createDeck(title: string): Promise<IDeck> {
    const created = Date.now();
    const newDeck: IDeck = {
      id: await generateId(),
      title,
      flashcards: [],
      modified: created,
      created,
      deleted: false,
    };

    return this.thunkDispatch(async (dispatch: IStoreDispatch,  getState: () => IStoreState) => {
      dispatch(actionStoreStatus('sending', newDeck.id));
      try {
        dispatch(actionCreateDeck(newDeck));
        dispatch(actionMarkPendingRemote(newDeck.id));

        this.myClient
          .createDeck(title)
          .then(response => {
            dispatch(
              actionUpdateDeck(newDeck.id, {
                remoteId: response.id,
              }),
            );
            dispatch(actionLinkPendingRemote(newDeck.id, response.id));
            if (response) {
              const state = getState();
              const latestDeck = extractDeck(newDeck.id, state.decks);
              const payload = latestDeck || newDeck;
              // we update the status for the temporary deck and the new one
              dispatch(actionStoreStatus('sent', response.id));
              dispatch(actionStoreStatus('sent', newDeck.id));

              const command: ICreateDeckCommand = {
                action: ICommandAction.Create,
                targetId: response.id,
                payload,
              };
              this.sendChanges(response.id, state, ITarget.Deck, command);
            }
          })
          .catch(() => {
            console.log('error sending new deck to AWS');
            dispatch(actionStoreStatus('failed', newDeck.id));
          });
        
        return newDeck;
      } catch (e) {
        console.error('Error creating new deck', e);
        dispatch(actionStoreStatus('failed', newDeck.id));
        return Promise.reject('Unable to store the deck');
      }
    });
  }

  async createFlashCard(
    deckId: string,
    title: string,
    atIndex?: number,
  ): Promise<IFlashCard> {
    const newFlashCard: IFlashCard = {
      id: await generateId(),
      title,
      bullets: [],
    };

    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        try {
          if (atIndex === undefined) {
            //appending a new flashcard
            dispatch(actionCreateFlashCard(deckId, newFlashCard));
          } else {
            //inserting a new flashcard at index [atIndex]
            dispatch(
              actionInsertFlashcardAtIndex(deckId, atIndex, newFlashCard),
            );
          }
          const state = getState();

          await this.handleDeckUpdate(state, deckId, dispatch);

          const command: ICreateFlashcardCommand = {
            action: ICommandAction.Create,
            targetId: newFlashCard.id,
            payload: newFlashCard,
            deckId,
            atIndex,
          };
          this.sendChanges(deckId, state, ITarget.Flashcard, command);

          return newFlashCard;
        } catch (e) {
          if (atIndex === undefined) {
            console.error('Error creating new flashcard', e);
          } else {
            console.error(
              `Error creating new flashcard at index ${atIndex}`,
              e,
            );
          }
          return Promise.reject('Unable to to store the flashcard');
        }
      },
    );
  }

  async createFlashCardsInBulk(
    deckId: string,
    flashcardsNumber: number,
  ): Promise<IDeck> {
    let newFlashcards: IFlashCard[] = [...Array(flashcardsNumber)].map(() => {
      return {
        id: generateIdWithStandardGenerator(),
        title: UNTITLED_FLASHCARD_PLACEHOLDER,
        bullets: [],
      };
    });

    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        try {
          const state = getState();

          const oldDeck = extractDeck(deckId, state.decks);

          if (!oldDeck) {
            return Promise.reject("Cannot update a deck that doesn't exist");
          }

          const newDeck: IDeck = {
            ...oldDeck,
            flashcards: [...oldDeck.flashcards, ...newFlashcards],
          };

          dispatch(actionUpdateDeck(newDeck.id, newDeck));

          const deckState = getState();

          await this.handleDeckUpdate(deckState, deckId, dispatch);

          return newDeck;
        } catch (e) {
          console.error('Error updating deck', e);
          return Promise.reject('Unable to update the deck');
        }
      },
    );
  }

  async createBullet(
    deckId: string,
    flashcardId: string,
    content: string,
  ): Promise<IBullet> {
    const newBullet: IBullet = {
      id: await generateId(),
      content,
      hint: null,
      undoHint: null,
    };

    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        try {
          dispatch(actionCreateBullet(deckId, flashcardId, newBullet));

          const state = getState();

          await this.handleDeckUpdate(state, deckId, dispatch);

          const command: ICreateBulletCommand = {
            action: ICommandAction.Create,
            targetId: newBullet.id,
            payload: newBullet,
            flashcardId,
            deckId,
          };
          this.sendChanges(deckId, state, ITarget.Bullet, command);

          return newBullet;
        } catch (e) {
          console.error('Error creating new bullet', e);
          return Promise.reject('Unable to store the bullet');
        }
      },
    );
  }

  async deleteFlashCard(
    deckId: string,
    flashcardId: string,
  ): Promise<IFlashCard | null> {
    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        try {
          let state = getState();

          const flashcard = extractFlashCard(flashcardId, state.decks);

          dispatch(actionDeleteFlashCard(deckId, flashcardId));

          state = getState();

          await this.handleDeckUpdate(state, deckId, dispatch);

          const command: IDeleteFlashcardCommand = {
            action: ICommandAction.Delete,
            targetId: flashcardId,
            deckId,
          };
          this.sendChanges(deckId, state, ITarget.Flashcard, command);

          return flashcard;
        } catch (e) {
          console.error('Error deleting flashcard', e);
          return Promise.reject('Unable to delete the flashcard');
        }
      },
    );
  }
  async deleteBullet(
    deckId: string,
    flashcardId: string,
    bulletId: string,
  ): Promise<IBullet | null> {
    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        try {
          let state = getState();

          const bullet = extractBullet(bulletId, state.decks);

          dispatch(actionDeleteBullet(deckId, flashcardId, bulletId));

          state = getState();

          await this.handleDeckUpdate(state, deckId, dispatch);

          const command: IDeleteBulletCommand = {
            action: ICommandAction.Delete,
            targetId: bulletId,
            flashcardId,
            deckId,
          };
          this.sendChanges(deckId, state, ITarget.Bullet, command);

          return bullet;
        } catch (e) {
          console.error('Error deleting bullet', e);
          return Promise.reject('Unable to delete the bullet');
        }
      },
    );
  }
  async moveFlashCard(
    deckId: string,
    flashcardId: string,
    indexChange: number,
  ): Promise<IDeck | null> {
    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        dispatch(actionChangeFlashCardIndex(deckId, flashcardId, indexChange));

        const state = getState();

        const deck = await this.handleDeckUpdate(state, deckId, dispatch);

        const command: IMoveFlashcardByOneIndexCommand = {
          action: ICommandAction.MoveByOneIndex,
          targetId: flashcardId,
          indexChange,
          deckId,
        };
        this.sendChanges(deckId, state, ITarget.Flashcard, command);

        return deck;
      },
    );
  }
  async moveBullet(
    deckId: string,
    flashcardId: string,
    bulletId: string,
    indexChange: number,
    isClone: boolean = false,
  ): Promise<IBullet | null> {
    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        try {
          let state = getState();

          const bullet = extractBullet(bulletId, state.decks);

          dispatch(
            actionChangeBulletIndex(deckId, flashcardId, bulletId, indexChange),
          );

          state = getState();

          if (!isClone) {
            await this.handleDeckUpdate(state, deckId, dispatch);

            const command: IMoveBulletByOneIndexCommand = {
              action: ICommandAction.MoveByOneIndex,
              deckId,
              targetId: bulletId,
              indexChange,
              flashcardId,
            };
            this.sendChanges(deckId, state, ITarget.Bullet, command);
          }

          return bullet;
        } catch (e) {
          console.error('Error updating bullet index', e);
          return Promise.reject('Unable to update the bullet index');
        }
      },
    );
  }

  async duplicateDeck(
    originalDeckId: string,
    newTitle?: string,
  ): Promise<IDeck> {
    let newDeck = await this.createDuplicateDeck(
      originalDeckId,
      newTitle ?? '',
    );

    return newDeck;
  }

  async createDuplicateDeck(
    originalDeckId: string,
    newTitle?: string,
    markAsClone?: boolean,
  ): Promise<IDeck> {
    return this.thunkDispatch(
      async (dispatch: IStoreDispatch, getState: () => IStoreState) => {
        const created = Date.now();
        // We create a temporary empty deck to populate
        const tempDeck = await this.createDeck(newTitle ?? '');

        const state = getState();
        // get the deck we wish to duplicate
        const originalDeck = extractDeck(originalDeckId, state.decks);
        if (!originalDeck) {
          return Promise.reject(
            'Unable to duplicate deck as original not found',
          );
        }
        // copy over the original contents into our new deck
        const newDeck: IDeck = {
          id: tempDeck.id,
          title: newTitle ?? originalDeck.title,
          flashcards: originalDeck.flashcards
            ? await Promise.all(
                originalDeck.flashcards.map(flashcard =>
                  this.createCloneOfFlashcard(flashcard, true),
                ),
              )
            : [],
          modified: created,
          created,
          deleted: false,
          originalId: markAsClone ? originalDeckId : '', // if a deck has an original ID it wont be sent to AWS
        };
        try {
          // update our new deck to save the changes
          dispatch(actionUpdateDeck(newDeck.id, newDeck));
          return newDeck;
        } catch (e) {
          console.error('Error creating duplicate deck', e);
          return Promise.reject('Unable to store the deck');
        }
      },
    );
  }
  private async createCloneOfFlashcard(
    original: IFlashCard,
    forDuplicateDeck?: boolean,
  ): Promise<IFlashCard> {
    return {
      ...original,
      id: await generateId(),
      originalId: forDuplicateDeck ? null : original.id,
      bullets: original.bullets
        ? await Promise.all(
            original.bullets.map(bullet =>
              this.createCloneOfBullet(bullet, forDuplicateDeck),
            ),
          )
        : [],
      reviewSelection: null,
    };
  }

  private async createCloneOfBullet(
    original: IBullet,
    forDuplicateDeck?: boolean,
  ): Promise<IBullet> {
    return {
      ...original,
      id: await generateId(),
      originalId: forDuplicateDeck ? null : original.id,
      hint: this.createCloneOfHint(original.hint),
    };
  }

  private createCloneOfHint(original: IHint<any> | null): IHint | null {
    let ret = null;

    if (original) {
      if (original.type === HintType.Image) {
        return new ImageHint(original.content);
      } else {
        ret = new TextHint(original.content);
      }
    }
    return ret;
  }

  async updateFlashcardsToMatchSlides(
    deckId: string,
    slidesInfo: SlideInfo[],
  ): Promise<IDeck> {
    return this.thunkDispatch(async (_, getState: () => IStoreState) => {
      try {
        const state = getState();
        const deck = extractDeck(deckId, state.decks, true);

        if (!deck) {
          return Promise.reject("Deck doesn't exist");
        }

        const [visibleFlashcards, deletedFlashcards] = splitAllFlashcards(
          deck.flashcards,
        );
        slidesInfo.forEach((slide, index) => {
          const flashcard = visibleFlashcards[index];
          const flashcardFinalTitle = getFinalTitleValue(slide.title);
          const flashcardTitle = getTruncatedTitle(flashcardFinalTitle);
          if (flashcard) {
            if (!flashcard.title || flashcard.title === UNTITLED_PLACEHOLDER) {
              flashcard.title = flashcardTitle;
            }
          } else {
            visibleFlashcards.push({
              id: generateIdWithStandardGenerator(),
              title: flashcardTitle,
              bullets: [],
            });
          }
        });

        const flashcards = visibleFlashcards.concat(deletedFlashcards);

        const newDeck = await this.updateDeck(deckId, { flashcards });
        //Use the newDeck id instead of the deckId as the deckId might got resolved to remote id and that is reflected during extraction of the deck
        const newDeckId = newDeck.id;
        const deckState = getState();

        const command: IUpdateDeckCommand = {
          action: ICommandAction.Update,
          targetId: deckId,
          payload: newDeck,
        };
        this.sendChanges(newDeckId, deckState, ITarget.Deck, command);

        return newDeck;
      } catch (e) {
        console.log(
          "Couldn't update deck to match the flashcards to the slides",
          e,
        );
        return Promise.reject(
          'Unable to update the deck to match the flashcards to the slides',
        );
      }
    });
  }

  private getActualId(state: IStoreState, deckId: string) {
    let actualDeckId = deckId;
    let remoteExists = false;
    const pendingRemoteId = state.decks.pendingRemoteIds[deckId];
    if (typeof pendingRemoteId !== 'undefined' && pendingRemoteId !== null) {
      // deck is being worked on locally and a switch is pending
      actualDeckId = pendingRemoteId;
    }

    if (pendingRemoteId || typeof pendingRemoteId === 'undefined') {
      remoteExists = true;
    }

    return {actualDeckId, remoteExists};
  }

  private sendChanges(
    deckId: string,
    state: IStoreState,
    target: ITarget,
    command: AllDeckRepoCommands,
  ) {
    const correctedCommand = {...command};
    // Check if there is a pending link for command.deckId, if it is null then noop
    const {remoteExists, actualDeckId} = this.getActualId(state, deckId);

    // If there is an established link, get the remote id
    if (remoteExists) {
      if ('deckId' in correctedCommand) {
        correctedCommand.deckId = actualDeckId;
      } else if ('targetId' in correctedCommand) {
        correctedCommand.targetId = actualDeckId;
      }

      // Then send the command with the remote deckId instead of the local
      this.myMessageManager?.sendChanges(target, correctedCommand);
    }
  }
}
