import { IDeck } from '@estendio/presentpal-store';
import { getInitialTitleValue } from '../InputHelper';
import { endSentenceForScreenReaders } from '../StringHelper';

export const getInitialDeckTitleValue = (deck: IDeck | null) => {
  return deck && deck.title ? getInitialTitleValue(deck.title) : '';
};

// Input lengths
export const MAX_LENGTH_DECK_TITLE = 50;
export const DECK_TITLE_COUNTDOWN_START = 40;

export const getSubtitle = (bulletsTotal: number) => {
  return bulletsTotal === 1
    ? `1 bullet added.`
    : `${bulletsTotal} bullets added`;
};

export const getAriaLabel = (
  index: number,
  title: string,
  bulletsTotal: number,
) => {
  const sentenceEnd = endSentenceForScreenReaders(title);
  const subtitleText = getSubtitle(bulletsTotal);
  return `${index + 1}. ${title}${sentenceEnd} ${subtitleText} `;
};
