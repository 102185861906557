import React from 'react';
import { USER_THEMES, Link } from '@estendio/presentpal-ui-kit';

export default function PresentModalFooter() {
  return (
    <div className="space-y-3">
      <p>
        For more support please visit our{' '}
        <Link
          text="support page"
          url="https://presentpal.co.uk/support-hub"
          theme={USER_THEMES.PRIMARY}
        />
        .
      </p>
      <p>
        You can also watch our{' '}
        <Link
          text="Present Pal video tutorials"
          url="https://www.youtube.com/playlist?list=PL_e08w8CT6CnUQ0D_xBq1nC8X8_9SkDmt"
          theme={USER_THEMES.PRIMARY}
        />
        .
      </p>
    </div>
  );
}
