export interface AppConfig {
  api: string;
  portal: string;
}

declare const app_config: AppConfig;

export function getConfig(): AppConfig {
  return app_config;
}
