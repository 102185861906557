import { nanoid } from 'nanoid';

export const getOS = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
};

export const UNTITLED_FLASHCARD_PLACEHOLDER = 'Untitled flashcard';

export const requiresPlaceholder = (text: string) => {
  if (!text || text === UNTITLED_FLASHCARD_PLACEHOLDER) {
    return true;
  }
  return false;
};

const bitsPerChar = 6;

//Uses hardware random bytes generation for security and low collision probability
export const generateId = async (bits: number = 256) => {
  return nanoid(Math.ceil(bits / bitsPerChar));
};
//Faster but less-secure generator
export const generateIdWithStandardGenerator = (bits: number = 256) => {
  return nanoid(Math.ceil(bits / bitsPerChar));
};
