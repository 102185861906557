import React, { FC } from 'react';
import { Blobs } from '@estendio/presentpal-ui-kit';
import ContentWrapper from './ContentWrapper';
import Footer from './footer/Footer';
import Header from './header/Header';

export interface PageWithNavProps {
  children: React.ReactNode;
  hasFooter?: boolean;
  navLabel: string;
  navOnClick: () => void;
  rightSideContent?: JSX.Element;
  statusIndicator?: JSX.Element;
}

const PageWithNav: FC<PageWithNavProps> = props => {
  const { children, hasFooter = true, ...headerProps } = props;
  return (
    <div className="h-screen flex flex-col">
      <Blobs />
      <Header {...headerProps} />
      <ContentWrapper>{children}</ContentWrapper>
      {hasFooter && <Footer />}
    </div>
  );
};

export default PageWithNav;
