import React from 'react';
import { AmplitudeClient } from 'amplitude-js';
import { useTypedSelector } from '@estendio/presentpal-store';
import { formatDistanceToNow } from 'date-fns';

const initAmplitude = () => {
  const apiKey =
    process.env.NODE_ENV === 'production'
      ? '8bfdd4cc8670c1caafa264f38bebdcf3'
      : '0a607092d5422d08e8b62e65901cbd01';

  const amplitude = require('amplitude-js');
  const amplitudeInstance: AmplitudeClient = amplitude.getInstance();

  amplitudeInstance.init(apiKey);

  return amplitudeInstance;
};

const ampInstance = initAmplitude();

const AmplitudeInstanceContext = React.createContext(ampInstance);

export const getTimePassedFromRegistration = (registerDate: string) => {
  return formatDistanceToNow(new Date(registerDate));
};

export const useAmplitude = () => {
  const user = useTypedSelector(state => state.user);
  const trackingId = user.trackingId ?? null;
  const tagId = user.licence.tag ?? null;
  const tagName = user.licence.tagName ?? null;
  const orgId = user.licence.organisation ?? null;
  const orgName = user.licence.organisationName ?? null;
  const timePassedFromRegistration = user.registered
    ? getTimePassedFromRegistration(user.registered)
    : null;
  const PowerPointVersion = Office?.context?.diagnostics?.version ?? null;

  const userProperties = {
    tag_id: tagId,
    tag_name: tagName,
    organisation_name: orgName,
    organisation_id: orgId,
    timePassedFromRegistration,
    domain: 'powerpoint add-in',
    PowerPointVersion,
  };
  const amp = React.useContext(AmplitudeInstanceContext);

  if (!amp) {
    throw new Error(
      'The component must be wrapped in AmplitudeProvider to use useAmplitude',
    );
  }

  amp.setUserId(trackingId);
  amp.setUserProperties(userProperties);

  return amp;
};

export const AmplitudeProvider = ({
  children,
}: React.PropsWithChildren<{}>): React.ReactElement => (
  <AmplitudeInstanceContext.Provider value={ampInstance}>
    {children}
  </AmplitudeInstanceContext.Provider>
);
