import { LoadingSpinner, USER_THEMES } from '@estendio/presentpal-ui-kit';
import React, { FC } from 'react';
import classNames from 'classnames';

interface LoadingProps {
  fullScreen?: boolean;
}

export const Loading: FC<LoadingProps> = ({ fullScreen = false }) => {
  const containerStyle = classNames('flex items-center justify-center', {
    'h-screen': fullScreen,
  });
  return (
    <div className={containerStyle}>
      <LoadingSpinner theme={USER_THEMES.PRIMARY} />
    </div>
  );
};
