import {
  ButtonPrimary,
  Header,
  TitleInput,
  USER_THEMES,
} from '@estendio/presentpal-ui-kit';
import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import PageWithNav from '../../components/pageTemplates/PageWithNav';
import { Routes } from '../../components/routing/types';
import { RepositoryContext } from '../../context/RepositoriesContext';
import {
  FC_TITLE_CHARS_COUNTDOWN_START,
  getInitialFlashcardTitleValue,
  MAX_LENGTH_FC_TITLE,
} from '../../helpers/editFlashcard/editFlashcard';
import useNavigation from '../../hooks/navigationHook';
import {
  createFlashcardSelect,
  useTypedSelector,
  IBullet,
} from '@estendio/presentpal-store';
import { EditFlashcardProps } from './types';
import { MdAdd } from 'react-icons/md';
import NavDeleteButton from '../../components/shared/NavDeleteButton';
import { DeleteModal } from '../../components/shared/DeleteModal';
import { EventType, AddBulletEvent } from '../../amplitude/Events';
import { logAmplitudeEvent } from '../../amplitude/logAmplitudeEvent';
import { useAmplitude } from '../../amplitude/AmplitudeService';
import { DraggableList } from '../../components/draggable/DraggableList';
import { DraggableBulletListItem } from '../../components/draggable/DraggableBulletListItem';
import CloudStatusByDeck from '../../components/shared/cloudStatus/CloudStatusByDeck';
import { OfficeContext } from '../../context/OfficeContext';
import { RouteContext } from '../../context/RouteContext';
import { debounce } from 'lodash';
import { DEBOUNCE_DELAY } from './values';
import { getFinalTitleValue } from '../../helpers/InputHelper';

const EditFlashcard: React.FC<EditFlashcardProps> = ({
  flashcardId,
  flashcardIndex,
  deckId,
}) => {
  const navigateTo = useNavigation();
  const { props } = useContext(RouteContext);
  const { slideInfo } = useContext(OfficeContext);
  const { deckRepository } = useContext(RepositoryContext);
  const amplitude = useAmplitude();
  const bulletToDelete = useRef<string>();
  const flashcardSelector = useMemo(
    () => createFlashcardSelect(flashcardId || ''),
    [flashcardId],
  );
  const decks = useTypedSelector(state => state.decks.decks.byId);
  const flashcard = useTypedSelector(flashcardSelector);

  const [deleteModalOpen, setDeleteModalOpen] = useState<
    null | 'flashcard' | 'bullet'
  >(null);

  const [titleInput, setTitleInput] = useState<string>(
    getInitialFlashcardTitleValue(flashcard),
  );

  const [editing, setEditing] = useState<string>('');

  const logAddBullet = (index: number) => {
    const eventType = EventType.AddBullet;
    const event: AddBulletEvent = {
      name: eventType,
      properties: {
        index,
      },
    };

    logAmplitudeEvent(amplitude, EventType.AddBullet, event);
  };

  const handleDeleteBulletClick = () => {
    setDeleteModalOpen('bullet');
    bulletToDelete.current = editing;
  };

  const handleDeleteBullet = () => {
    if (bulletToDelete.current) {
      deckRepository.deleteBullet(deckId, flashcardId, bulletToDelete.current);
    }
    setDeleteModalOpen(null);
  };

  const handleAddBullet = () => {
    logAddBullet(flashcard?.bullets.length || 0);
    deckRepository
      ?.createBullet(deckId, flashcardId, '')
      .then(bullet => setEditing(bullet.id)); // sets the new bullet in edit mode
  };

  const handleSetRouteBack = () => {
    navigateTo(Routes.EditDeck, { deckId });
  };

  const handleSetRouteToSafetyNet = (bullet: IBullet) => {
    navigateTo(Routes.SafetyNet, {
      deckId,
      flashcardId,
      flashcardIndex,
      bullet,
    });
  };

  const handleDeleteFlashcard = () => {
    deckRepository.deleteFlashCard(deckId, flashcardId);
    navigateTo(Routes.EditDeck, { deckId });
    setDeleteModalOpen(null);
  };

  const handleTitleSaving = useCallback(
    (title: string) => {
      if (!flashcard || title === flashcard.title) {
        return;
      }
      deckRepository?.updateFlashCard(deckId, flashcard.id, {
        //Prevents empty string being sent to the AWS
        title: getFinalTitleValue(title),
      });
    },
    [deckId, deckRepository, flashcard],
  );

  const debouncedTitleSave = useMemo(
    () => debounce(handleTitleSaving, DEBOUNCE_DELAY),
    [handleTitleSaving],
  );

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    if (val.length <= MAX_LENGTH_FC_TITLE) {
      const title = e.currentTarget.value;
      setTitleInput(title);
      debouncedTitleSave(title);
    }
  };

  const deleteBtn = (
    <NavDeleteButton
      type="flashcard"
      onClick={() => setDeleteModalOpen('flashcard')}
    />
  );
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Closes bullet edit mode when user clicks outside the bulletList
    const handleClickOutside = (event: MouseEvent) => {
      const ev = event.target as Node;
      //Prevents exiting edit mode when the user clicks on a delete modal
      const deleteModalIsOpen = document.getElementById('pp-modal-container');
      if (!!deleteModalIsOpen) {
        return;
      }
      if (event.target && listRef.current && !listRef.current.contains(ev)) {
        setEditing('');
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  //Handles syncing the flashcards to selected slide
  //stopAutoSync disables the effect to prevent unnecessary re-rendering and update of the list that happens already on navigation
  // const [stopAutoSync, setStopAutoSync] = useState(!!props.stopAutoSync);
  const stopAutoSync = useRef(!!props.stopAutoSync);
  useEffect(() => {
    if (stopAutoSync.current) {
      stopAutoSync.current = false;
      return;
    }

    if (slideInfo && slideInfo[0]) {
      const slideIndex = slideInfo[0].index; //starts from 1
      const flashcardsIds = decks[deckId].flashcards;

      if (
        slideIndex > flashcardsIds.length ||
        flashcardsIds.length === 0 ||
        slideIndex - 1 === flashcardIndex
      ) {
        return;
      }

      navigateTo(Routes.EditFlashcard, {
        deckId,
        flashcardIndex: slideIndex - 1,
        flashcardId: flashcardsIds[slideIndex - 1],
      });
    }
    //Needed flashcard dependency to keep the correct input value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideInfo]);

  useEffect(() => {
    //Allows for empty string
    setTitleInput(getInitialFlashcardTitleValue(flashcard));
  }, [flashcard]);

  const renderBullets = (bullet: IBullet, index: number) => {
    return (
      <DraggableBulletListItem
        index={index}
        editing={bullet.id === editing}
        setEditing={(id: string) => setEditing(id)}
        onDeleteClick={handleDeleteBulletClick}
        deckId={deckId}
        flashcardId={flashcardId}
        bullet={bullet}
        onSafetyNetClick={() => handleSetRouteToSafetyNet(bullet)}
        isDragDisabled={!!editing}
        key={bullet.id}
      />
    );
  };

  const updateBulletIndex = (
    draggableId: string,
    sourceIndex: number,
    destinationIndex: number,
  ) => {
    const positionDiff = destinationIndex - sourceIndex;
    if (draggableId && positionDiff) {
      deckRepository.moveBullet(deckId, flashcardId, draggableId, positionDiff);
    }
  };

  return (
    <PageWithNav
      rightSideContent={deleteBtn}
      navOnClick={handleSetRouteBack}
      navLabel="Edit Flashcard"
      statusIndicator={<CloudStatusByDeck deckId={deckId} />}
    >
      <div>
        <TitleInput
          placeholder={'Enter a title'}
          id="fcTitle"
          onChange={onTitleChange}
          currentLength={titleInput.length}
          maxLength={MAX_LENGTH_FC_TITLE}
          countdownFrom={FC_TITLE_CHARS_COUNTDOWN_START}
          label={'Flashcard title'}
          value={titleInput}
        />
      </div>
      <div className="pt-8 pb-2">
        <Header tag="h2" size="sm">
          Bullet points
        </Header>
      </div>
      {flashcard && flashcard.bullets.length !== 0 && (
        <div ref={listRef}>
          <DraggableList
            id="bullet list"
            onItemDrag={updateBulletIndex}
            items={flashcard.bullets}
            renderItem={renderBullets}
          />
        </div>
      )}
      <ButtonPrimary
        fullWidth
        buttonSize="lg"
        onClick={handleAddBullet}
        theme={USER_THEMES.PRIMARY}
        label="ADD BULLET"
        icon={<MdAdd />}
      />
      <DeleteModal
        isDeleteModalOpen={!!deleteModalOpen}
        closeDeleteModal={() => setDeleteModalOpen(null)}
        handleDelete={
          deleteModalOpen === 'bullet'
            ? handleDeleteBullet
            : handleDeleteFlashcard
        }
        type={deleteModalOpen ? deleteModalOpen : 'flashcard'}
      />
    </PageWithNav>
  );
};

export default EditFlashcard;
