import React, { useEffect, useRef } from 'react';
import { Draggable, DraggableStyle } from '@hello-pangea/dnd';
import classNames from 'classnames';
import { USER_THEMES } from '@estendio/presentpal-ui-kit';

export interface DraggableItemBaseProps {
  id: string;
}
export interface DraggableItemProps extends DraggableItemBaseProps {
  index: number;
  theme: USER_THEMES;
  children: React.ReactNode;
  ariaLabel?: string;
  onItemClick?: () => void;
  isDragDisabled?: boolean;
}

export function DraggableItem({
  id,
  index,
  theme,
  ariaLabel,
  children,
  onItemClick,
  isDragDisabled = false,
}: DraggableItemProps) {
  // Do not add transition as it messes up the library default animation
  const itemStyle = classNames(
    'list-none',
    'rounded-lg',
    'flex',
    'items-center',
    'justify-between',
    'w-full',
    'text-slate-900',
    'mb-3', // style only bottom margin for the snapping to be precise
    'text-left',
    'shadow',
    [`bg-${theme}-200 hover:bg-${theme}-300 active:bg-${theme}-100`],
    'overflow-hidden',
    'relative',
  );
  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggableStyle | undefined,
  ) => {
    //Since we are using inline styling add other theme-800 colours values if needed
    let themedOutlineColor;
    switch (theme) {
      case USER_THEMES.PRIMARY:
      default:
        themedOutlineColor = '#065f46';
    }
    return isDragging
      ? {
          // style on dragging
          outline: `dashed 2px ${themedOutlineColor}`,
          outlineOffset: 1,
          boxShadow: '0 25px 25px rgba(0, 0, 0, 0.25)',
          borderRadius: 8,
          // the styles we need to apply on draggables! DO NOT REMOVE!
          ...draggableStyle,
        }
      : draggableStyle;
  };

  const itemRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    const element = itemRef.current?.parentElement;

    const handleTabbing = (event: any) => {
      //Space hit triggers dragging
      if (event.code === 'Enter' && onItemClick) {
        onItemClick();
      }
    };
    if (element) {
      element.addEventListener('keydown', handleTabbing);
    }
    return () => {
      if (element) {
        element.removeEventListener('keydown', handleTabbing);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
          className={itemStyle}
          aria-label={ariaLabel}
          onClick={onItemClick}
        >
          <span ref={itemRef} />
          {children}
        </li>
      )}
    </Draggable>
  );
}
