import React from 'react';
import {
  DeckListItem,
  USER_THEMES,
  ButtonPrimary,
} from '@estendio/presentpal-ui-kit';
import { IDeckSummary } from '@estendio/presentpal-store';
import { getAriaLabel, getDeckSubtitle } from '../../helpers/deckList/deckList';
import { MdAdd } from 'react-icons/md';

export default function List({
  decks,
  handleSetRouteToEditDeck,
  handleAddDeck,
  matchSlidesToFlashcards,
}: {
  decks: IDeckSummary[];
  handleSetRouteToEditDeck: (deckId: string) => void;
  handleAddDeck: () => void;
  matchSlidesToFlashcards: (deckId: string) => void;
}) {
  return (
    <ul aria-label="List of decks" className="space-y-2 pb-3">
      <li className="pb-2">
        <ButtonPrimary
          icon={<MdAdd />}
          theme={USER_THEMES.PRIMARY}
          label="Create new deck"
          onClick={handleAddDeck}
          fullWidth
          buttonSize="lg"
        />
      </li>
      {decks.map(deck => {
        const subtitle = getDeckSubtitle(deck.flashcards, deck.created);
        const handleOnClick = () => {
          matchSlidesToFlashcards(deck.id);
          handleSetRouteToEditDeck(deck.id);
        };
        const ariaLabel = getAriaLabel(deck);
        return (
          <DeckListItem
            theme={USER_THEMES.PRIMARY}
            key={deck.id}
            title={deck.title}
            subtitle={subtitle}
            onClick={handleOnClick}
            ariaLabel={ariaLabel}
            showBackgroundImage={false}
          />
        );
      })}
    </ul>
  );
}
