import React, { FC } from 'react';
import {
  selectAnyUnsavedChanges,
  useTypedSelector,
  IDeckSummary,
} from '@estendio/presentpal-store';
import CloudStatusIcon from './CloudStatusIcon';

interface AllDecksCloudStatusProps {
  decks: IDeckSummary[];
}

export const CloudStatusAllDecks: FC<AllDecksCloudStatusProps> = ({
  decks,
}) => {
  const unsavedChanges = useTypedSelector(selectAnyUnsavedChanges(decks));

  return <CloudStatusIcon status={unsavedChanges ? 'failed' : 'sent'} />;
};
