import { TextArea } from '@estendio/presentpal-ui-kit';
import React, { useContext, useState } from 'react';
import { RepositoryContext } from '../../context/RepositoriesContext';
import { IBullet, HintType } from '@estendio/presentpal-store';
import { editBullet } from '../../helpers/safetyNet/safetyNet';

export default function ContentTextSafetyNet({
  deckId,
  bullet,
  flashcardId,
}: {
  deckId: string;
  bullet: IBullet;
  flashcardId: string;
}) {
  const { deckRepository } = useContext(RepositoryContext);
  const [textHintInput, setTextHintInput] = useState(
    bullet && bullet.hint?.type === HintType.Text ? bullet.hint?.content : '',
  );
  const handleTextHintSubmit = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    let update;
    if (!text || text === '') {
      update = { hint: null, undoHint: null };
    } else {
      update = { hint: { type: HintType.Text, content: text } };
    }
    editBullet(deckRepository, bullet.id, deckId, update, flashcardId);
  };

  const handleTextHintOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const text = e.target.value;
    setTextHintInput(text);
  };

  return (
    <TextArea
      id="safetynet-text"
      ariaLabel="Add a safety net"
      value={textHintInput}
      onBlur={handleTextHintSubmit}
      onChange={handleTextHintOnChange}
      autoFocus={textHintInput ? false : true}
    />
  );
}
