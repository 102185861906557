import React, { createContext, ReactNode, useState } from 'react';
import { IDeckRepository } from '../repository/deck';
import { IPresentationRepository } from '../repository/presentation';
import { IUserRepository } from '../repository/user';

export type SetStateType<S> = React.Dispatch<React.SetStateAction<S>>;

export interface IRepositoryContext {
  userRepository: IUserRepository;
  setUserRepository: SetStateType<IUserRepository>;
  deckRepository: IDeckRepository;
  setDeckRepository: SetStateType<IDeckRepository>;
  presentationRepository: IPresentationRepository;
  setPresentationRepository: SetStateType<IPresentationRepository>;
}

export interface IRepositoryContextProps {
  userRepository: IUserRepository;
  deckRepository: IDeckRepository;
  presentationRepository: IPresentationRepository;
  children: ReactNode;
}

export const RepositoryContext = createContext({} as IRepositoryContext);

const RepositoryProvider: React.FC<IRepositoryContextProps> = ({
  userRepository,
  deckRepository,
  presentationRepository,
  children,
}) => {
  const [userRepos, setUserRepository] = useState(userRepository);
  const [deckRepos, setDeckRepository] = useState(deckRepository);
  const [presentationRepos, setPresentationRepository] = useState(
    presentationRepository,
  );

  const store: IRepositoryContext = {
    userRepository: userRepos,
    setUserRepository,
    deckRepository: deckRepos,
    setDeckRepository,
    presentationRepository: presentationRepos,
    setPresentationRepository,
  };

  return (
    <RepositoryContext.Provider value={store}>
      {children}
    </RepositoryContext.Provider>
  );
};

export default RepositoryProvider;
