import React, { useContext, useState } from 'react';
import {
  IconButton,
  USER_THEMES,
  ConfirmationModal,
} from '@estendio/presentpal-ui-kit';
import { MdOutlineLogout } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { logout } from '../../../helpers/logout/logout';
import useNavigation from '../../../hooks/navigationHook';
import { Routes } from '../../routing/types';
import { RepositoryContext } from '../../../context/RepositoriesContext';

export default function LogoutModal() {
  const { userRepository } = useContext(RepositoryContext);
  const dispatch = useDispatch();
  const navigateTo = useNavigation();
  const handleLogout = () => {
    logout(userRepository, dispatch);
    navigateTo(Routes.Login, undefined);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpening = () => setIsModalOpen(true);
  const handleModalClosing = () => setIsModalOpen(false);
  const handleModalPrimaryAction = () => {
    handleLogout();
    setIsModalOpen(false);
  };
  return (
    <>
      <abbr title="Log out">
        <IconButton
          icon={<MdOutlineLogout />}
          iconSize="xl"
          ariaLabel="Log out"
          theme={USER_THEMES.PRIMARY}
          onClick={handleModalOpening}
        />
      </abbr>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleModalClosing}
        heading="Log out of Present Pal?"
        primaryButtonLabel="LOG OUT"
        secondaryButtonLabel="CANCEL"
        primaryButtonAction={handleModalPrimaryAction}
        secondaryButtonAction={handleModalClosing}
      />
    </>
  );
}
