import React from 'react';
import { USER_THEMES, NavButton } from '@estendio/presentpal-ui-kit';
import { PageWithNavProps } from '../PageWithNav';

export interface PageTemplateHeaderProps
  extends Omit<PageWithNavProps, 'children' | 'hasFooter'> {}
export default function Header({
  navLabel,
  navOnClick,
  rightSideContent,
  statusIndicator,
}: PageTemplateHeaderProps) {
  return (
    <>
      <div className="pt-5 pr-5 pl-5">{statusIndicator && statusIndicator}</div>

      <header className="flex items-center pr-4">
        <nav className="ml-3 mb-4 pt-3 flex flex-1">
          <NavButton
            theme={USER_THEMES.PRIMARY}
            text={navLabel}
            onClick={navOnClick}
          />
        </nav>
        {rightSideContent && rightSideContent}
      </header>
    </>
  );
}
