import { ConfirmationModal } from '@estendio/presentpal-ui-kit';
import React from 'react';

export interface DeleteModalProps {
  isDeleteModalOpen: boolean;
  handleDelete: () => void;
  closeDeleteModal: () => void;
  type: 'deck' | 'flashcard' | 'bullet' | 'safety net';
}
export const DeleteModal = ({
  isDeleteModalOpen,
  handleDelete,
  closeDeleteModal,
  type,
}: DeleteModalProps) => {
  return (
    <ConfirmationModal
      isOpen={isDeleteModalOpen}
      heading={`Delete ${type}?`}
      primaryButtonAction={handleDelete}
      secondaryButtonAction={closeDeleteModal}
      primaryButtonLabel="Delete"
      secondaryButtonLabel="Cancel"
      onClose={closeDeleteModal}
    >
      <p className="pt-2">
        Are you sure you want to delete this {type}? This action can't be
        undone.
      </p>
    </ConfirmationModal>
  );
};
