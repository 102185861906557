import { ButtonSecondary, USER_THEMES } from '@estendio/presentpal-ui-kit';
import React from 'react';
import { HiMenuAlt2 } from 'react-icons/hi';
import { MdAddPhotoAlternate } from 'react-icons/md';

export default function ContentDefault({
  setOpenTextSafetyNetEdit,
  setOpenImageSafetyNetEdit,
}: {
  setOpenTextSafetyNetEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenImageSafetyNetEdit: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const handleTextButtonOnClick = () => {
    setOpenTextSafetyNetEdit(true);
  };

  const handleImageButtonOnClick = () => {
    setOpenImageSafetyNetEdit(true);
  };

  return (
    <div className="space-y-2">
      <ButtonSecondary
        buttonSize="xl"
        label="ADD TEXT"
        theme={USER_THEMES.PRIMARY}
        icon={<HiMenuAlt2 />}
        fullWidth={true}
        onClick={handleTextButtonOnClick}
      />
      <ButtonSecondary
        buttonSize="xl"
        label="ADD IMAGE"
        theme={USER_THEMES.PRIMARY}
        icon={<MdAddPhotoAlternate />}
        fullWidth={true}
        onClick={handleImageButtonOnClick}
      />
    </div>
  );
}
