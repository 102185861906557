import {
  IStoreDispatch,
  IThunkDispatch,
  actionCreateDeck,
  actionDeleteDeck,
  actionUpdateDeck,
} from '@estendio/presentpal-store';
import {
  ICommandAction,
  ICreateDeckCommand,
  IDeckCommands,
  IDeleteDeckCommand,
  IMessage,
  IUpdateDeckCommand,
} from '@estendio/presentpal-rdm';

function handleChangesOnDeckCreation(
  command: ICreateDeckCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) => {
    if (command.targetId !== command.payload.id) {
      command.payload.id = command.targetId;
    }
    dispatch(actionCreateDeck(command.payload, false));
  });
}
function handleChangesOnDeckDeletion(
  command: IDeleteDeckCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) => {
    dispatch(actionDeleteDeck(command.targetId));
  });
}
function handleChangesOnDeckUpdate(
  command: IUpdateDeckCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) => {
    dispatch(actionUpdateDeck(command.targetId, command.payload, false));
  });
}

function handleDeckChanges(
  data: IMessage<IDeckCommands>,
  thunkDispatch: IThunkDispatch,
) {
  const { command } = data;
  switch (command.action) {
    //Handles both deck creation and duplication
    case ICommandAction.Create:
      handleChangesOnDeckCreation(command, thunkDispatch);
      break;
    case ICommandAction.Delete:
      handleChangesOnDeckDeletion(command, thunkDispatch);
      break;
    case ICommandAction.Update:
      handleChangesOnDeckUpdate(command, thunkDispatch);
      break;
  }
}

export default handleDeckChanges;
