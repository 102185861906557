import React, { useCallback, useContext, useEffect, useState } from 'react';
import { App } from './App';
import { OfficeContext } from './context/OfficeContext';
import { RepositoryContext } from './context/RepositoriesContext';
import {
  ConnectionManager,
  MessageManager,
  PresentationManager,
  IConnectionManager,
  ICommandAction,
  IMessage,
  PresentationCommand,
} from '@estendio/presentpal-rdm';
import { useTypedSelector, IStoreState } from './redux/store';
import { IUserState } from '@estendio/presentpal-store';
import { OfficeService } from './services/OfficeService/OfficeService';

function handleSlideChange(
  message: IMessage<PresentationCommand>,
  officeService: OfficeService,
  presentationId: string | undefined,
) {
  if (
    message.command.action === ICommandAction.GoToSlide &&
    presentationId &&
    message.command.presentationId === presentationId
  ) {
    officeService.goToSlide(message.command.slideIndex);
  }
}

const AppWrapper = () => {
  const { isPresenting, officeService } = useContext(OfficeContext);
  const { presentationRepository, userRepository, deckRepository } = useContext(
    RepositoryContext,
  );
  const [localPresentationId, setLocalPresentationId] = useState<string>('');
  const [sessionId, setSessionId] = useState<string>('');
  const user: IUserState = useTypedSelector((state: IStoreState) => {
    return state.user;
  });

  const startPresentationSession = useCallback(
    (id: string) => {
      presentationRepository.myPresentationManager?.setPresentationId(id);

      presentationRepository.myPresentationManager?.preparePresentationSession(
        sessionId,
      );

      const messageHandler = (message: IMessage<PresentationCommand>) =>
        handleSlideChange(message, officeService, id);
      deckRepository.myMessageManager?.onPresentationChange(messageHandler);
    },
    [
      deckRepository.myMessageManager,
      officeService,
      presentationRepository.myPresentationManager,
      sessionId,
    ],
  );

  const stopPresentationSession = useCallback(() => {
    if (presentationRepository.myPresentationManager?.presentationId) {
      presentationRepository.myPresentationManager?.disconnectPresentationSession();
    }
  }, [presentationRepository.myPresentationManager]);

  useEffect(() => {
    if (isPresenting) {
      if (!localPresentationId) {
        const id = new Date().toString();
        setLocalPresentationId(id);
        startPresentationSession(id);
      }
    } else {
      stopPresentationSession();
      setLocalPresentationId('');
    }
  }, [
    isPresenting,
    localPresentationId,
    startPresentationSession,
    stopPresentationSession,
  ]);

  useEffect(() => {
    if (!user.token) {
      return;
    }
    let myConnectionManager: IConnectionManager;
    myConnectionManager = new ConnectionManager(async () => {
      let url = await userRepository
        .getComms(window.navigator.platform)
        .then(result => {
          setSessionId(result.id);
          return result.url;
        })
        .catch(e => {
          console.log('Error occurred during socket setup', { error: e });
          setSessionId('');
          return undefined;
        });
      return url;
    });
    myConnectionManager.connect();
    const myMessageManager = new MessageManager(myConnectionManager);
    deckRepository.setMessageManager(myMessageManager);
    const myPresentationManager = new PresentationManager(myMessageManager);
    presentationRepository.setPresentationManager(myPresentationManager);

    return () => {
      myConnectionManager.close(); // to make sure connection is closed when user logs out
    };
  }, [user.token, deckRepository, userRepository, presentationRepository]);

  return <App />;
};

export default AppWrapper;
