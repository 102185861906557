export enum EventType {
  Login = 'login',
  CreateDeck = 'create deck',
  PageView = 'pageview',
  PresentButton = 'present button clicked',
  CreateFlashcard = 'create flashcard',
  AddBullet = 'add bullet',
}

export enum EventSuccess {
  True = 'true',
  False = 'false',
}

export enum TrackedPage {
  Home = 'home screen',
  EditDeck = 'edit deck',
}

export interface EventBody<event extends EventType> {
  name: event;
}

export interface LoginEvent extends EventBody<EventType.Login> {
  properties: {
    success: EventSuccess;
    error: string | null;
    rememberMe?: boolean;
  };
}

export type CreateDeckEventProperties = {
  deckId: string;
  totalDecks: number;
  timePassedFromRegistration: string;
};

export interface CreateDeckEvent extends EventBody<EventType.CreateDeck> {
  properties: CreateDeckEventProperties;
}

export interface PageViewEvent extends EventBody<EventType.PageView> {
  properties: {
    page: TrackedPage;
  };
}

export interface CreateFlashcardEvent
  extends EventBody<EventType.CreateFlashcard> {
  properties: {
    index: number;
    editDeckVersion?: number;
  };
}

export interface AddBulletEvent extends EventBody<EventType.AddBullet> {
  properties: {
    index: number;
  };
}

export interface PresentButtonEvent extends EventBody<EventType.PresentButton> {
  properties: {};
}

export type AmplitudeEvent =
  | LoginEvent
  | CreateDeckEvent
  | CreateFlashcardEvent
  | PageViewEvent
  | AddBulletEvent
  | PresentButtonEvent;
