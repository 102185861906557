import {
  LoginResultSuccess,
  LoginResultFailure,
  IUserClient,
} from '@estendio/presentpal-auth';

import {
  PortalUserDetails,
  PortalLicenceDetails,
  SuccessUserDetailsResponse,
  GenericFailedResponse,
} from '@estendio/presentpal-auth/dist/portal/types';
import { IAwsCommsSession } from '@estendio/presentpal-auth/dist/cloudDeckStorage/types';

export type Credentials = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export interface IUserRepository {
  signIn(
    credentials: Credentials,
  ): Promise<LoginResultFailure | LoginResultSuccess>;
  logOut(): void;
  isLoggedIn(): Promise<boolean>;
  forgotPassword(email: string): Promise<boolean>;
  checkUserDetails(): Promise<
    SuccessUserDetailsResponse | GenericFailedResponse
  >;
  getComms(os: string): Promise<IAwsCommsSession>;
}

/**
 * Response from Portal check-details which returns nice $user and $licence objects ready for the app
 */
export interface UserCheckResult {
  success: true;
  user: PortalUserDetails;
  licence: PortalLicenceDetails;
}
export default class UserRepository implements IUserRepository {
  private myUserClient: IUserClient;

  constructor(client: IUserClient) {
    this.myUserClient = client;
  }

  async signIn({
    email,
    password,
    rememberMe,
  }: Credentials): Promise<LoginResultFailure | LoginResultSuccess> {
    const login = await this.myUserClient.login(email, password, rememberMe);

    if (login.success && login.portal) {
      return login;
    }
    throw new Error('Login failed.');
  }

  async checkUserDetails(): Promise<
    SuccessUserDetailsResponse | GenericFailedResponse
  > {
    return await this.myUserClient.checkUserDetails();
  }

  logOut() {
    this.myUserClient.clearOutCredentials();
  }

  isLoggedIn() {
    return this.myUserClient.checkLoggedIn();
  }

  async forgotPassword(email: string): Promise<boolean> {
    return await this.myUserClient.reset(email);
  }

  async getComms(os: string): Promise<IAwsCommsSession> {
    const result = await this.myUserClient.getComms(os);
    return result;
  }
}
