import { EditDeckProps } from '../../pages/editDeck/types';
import { EditFlashcardProps } from '../../pages/editFlashcard/types';
import { ForgotPasswordProps } from '../../pages/forgotPassword/types';
import { SafetyNetProps } from '../../pages/safetyNet/types';

export enum Routes {
  Loading = 'loading',
  Login = 'login',
  ForgotPassword = 'forgotPassword',
  DeckList = 'deckList',
  EditDeck = 'editDeck',
  EditFlashcard = 'editFlashcard',
  SafetyNet = 'safetyNet',
}

export type RoutePropList = {
  [Routes.Loading]: undefined;
  [Routes.Login]: undefined;
  [Routes.ForgotPassword]: ForgotPasswordProps;
  [Routes.DeckList]: undefined;
  [Routes.EditDeck]: EditDeckProps;
  [Routes.EditFlashcard]: EditFlashcardProps;
  [Routes.SafetyNet]: SafetyNetProps;
};
