import React, { FC } from 'react';

interface ContentWrapperProps {
  children: React.ReactNode;
}

const ContentWrapper: FC<ContentWrapperProps> = ({ children }) => {
  return (
    <div className="p-5 flex-1 flex flex-col overflow-auto">{children}</div>
  );
};

export default ContentWrapper;
