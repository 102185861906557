import { IconButton, USER_THEMES } from '@estendio/presentpal-ui-kit';
import React, { FC } from 'react';
import { MdDeleteForever } from 'react-icons/md';

interface NavDeleteButtonProps {
  onClick: () => void;
  type: 'flashcard' | 'safety net';
}

const NavDeleteButton: FC<NavDeleteButtonProps> = ({ onClick, type }) => {
  return (
    <abbr title={`Delete ${type}`}>
      <IconButton
        onClick={onClick}
        ariaLabel={`Delete ${type}. Opens a modal`}
        theme={USER_THEMES.PRIMARY}
        icon={<MdDeleteForever />}
        iconSize="xl"
      />
    </abbr>
  );
};

export default NavDeleteButton;
