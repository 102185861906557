import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';

/**
 *
 * https://dnd.hellopangea.com/?path=/story/examples-board--simple
 *
 * @param onItemDrag // pass the method to update the elements position in the store using result: result.destination.index, result.source.index and result.draggableId
 * @param id // used as a unique list id and the aria label
 */

export interface DraggableListProps<T> {
  onItemDrag: (
    draggableId: string,
    sourceIndex: number,
    destinationIndex: number,
  ) => void;
  items: T[];
  renderItem: (item: T, index: number) => React.ReactElement | null;
  id: string;
}

export function DraggableList<T>({
  onItemDrag,
  items,
  renderItem,
  id,
}: DraggableListProps<T>) {
  const [list, setList] = useState(items);

  useEffect(() => {
    setList(items);
  }, [items]);

  const reorder = (list: T[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleOnDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const reorderedList = reorder(
      list,
      result.source.index,
      result.destination.index,
    );
    setList(reorderedList);
    onItemDrag(
      result.draggableId,
      result.source.index,
      result.destination.index,
    );
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId={id}>
        {droppableProvided => {
          return (
            <>
              <ul
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
                className=" text-slate-900"
                aria-label={id}
              >
                {list.map(renderItem)}
              </ul>
              {droppableProvided.placeholder}
            </>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}
