import React from 'react';
import { createContext, useMemo, useState } from 'react';
import { RoutePropList, Routes } from '../components/routing/types';

// See the README for info on why the hell we can't just use react-router-dom.

interface BaseRouteContextProps {
  route: Routes;
  props?: any;
}

interface RouteContextProps extends BaseRouteContextProps {
  // This gives us nice type safety when calling setRoute ensuring we get the correct props for the route
  setRoute: <R extends Routes, P extends RoutePropList[R]>(
    route: R,
    props: P, // don't make this optional as it removes type safety for props. Just pass undefined when no props needed
  ) => void;
}

export interface RouteContextProviderProps {
  children: React.ReactNode;
}

export const RouteContext = createContext<RouteContextProps>({} as any);

export const RouteContextProvider = ({
  children,
}: RouteContextProviderProps) => {
  const defaultRoute: BaseRouteContextProps = {
    route: Routes.Loading,
  };

  const [routeProps, setRouteProps] = useState<BaseRouteContextProps>(
    defaultRoute,
  );

  const safeSetRoute = <R extends Routes, P extends RoutePropList[R]>(
    route: R,
    props: P, // don't make this optional as it removes type safety for props. Just pass undefined when no props needed
  ) => {
    setRouteProps({ route, props });
  };

  const props: RouteContextProps = useMemo(() => {
    return {
      route: routeProps.route,
      props: routeProps.props ?? undefined,
      setRoute: safeSetRoute,
    };
  }, [routeProps.props, routeProps.route]);

  return (
    <RouteContext.Provider value={props}>{children}</RouteContext.Provider>
  );
};
