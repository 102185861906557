import Validator from '../Validator';
import {
  actionSigninFailure,
  actionSigninSuccess,
  UpdatePortalDetails,
  IUserLicenseDetails,
  IAuthenticateSignInFailure,
  IAuthenticateSignInSuccess,
  IAuthenticateUpdatePortalDetails,
  SignInFailure,
} from '@estendio/presentpal-store';
import { IUserRepository } from '../../repository/user';
import {
  PortalLicenceDetails,
  PortalUserDetails,
} from '@estendio/presentpal-auth/dist/portal/types';

interface checkErrorReturn {
  error: string;
  trimmedInput: string;
}

interface checkPasswordReturn {
  error: string;
  isValid: boolean;
}

export const checkPassword = (
  input: string | undefined,
): checkPasswordReturn => {
  let error = '';
  const validPassword = Validator.isValidPasswordv3(input);
  if (!input || !validPassword) {
    error = 'Please provide your password';
  }

  return { error, isValid: validPassword };
};

export const checkEmail = (input: string | undefined): checkErrorReturn => {
  let error = '';
  let trimmedEmail = '';
  if (!input || input.length <= 0) {
    error = 'Please provide your email address';
  } else {
    trimmedEmail = input.trim();
  }

  if (input && !Validator.isValidEmail(trimmedEmail)) {
    error = 'You have entered an invalid email address. Please try again';
  }
  return { error, trimmedInput: trimmedEmail };
};

const handleValidLogin = (
  userRepository: IUserRepository,
  dispatch: React.Dispatch<
    IAuthenticateUpdatePortalDetails | IAuthenticateSignInSuccess
  >,
  onExpired: () => void,
  onSuccess: () => void,
  onFailure: () => void,
  signInCreds: { token: string; licence: IUserLicenseDetails },
) => {
  checkUserDetailsWithExpiry(userRepository, dispatch).then(expiryCheck => {
    switch (expiryCheck) {
      case 'expired':
        onExpired();
        break;
      case 'error':
        onFailure();
        break;
      case 'success':
        dispatch(actionSigninSuccess(signInCreds.licence, signInCreds.token));
        onSuccess();
        break;
    }
  });
};

export const login = (
  userRepository: IUserRepository,
  dispatch: React.Dispatch<
    | IAuthenticateSignInSuccess
    | IAuthenticateSignInFailure
    | IAuthenticateUpdatePortalDetails
  >,
  email: string,
  password: string,
  rememberMe: boolean,
  onExpired: () => void,
  onSuccess: () => void,
  onFailure: () => void,
) => {
  userRepository
    .signIn({ email, password, rememberMe })
    .then(res => {
      if (res.success) {
        handleValidLogin(
          userRepository,
          dispatch,
          onExpired,
          onSuccess,
          onFailure,
          { token: res.aws.token, licence: res.portal },
        );
      } else {
        dispatch(actionSigninFailure(SignInFailure.Credentials));
        onFailure();
      }
    })
    .catch(err => {
      console.error('Error in login', err);
      dispatch(actionSigninFailure(SignInFailure.Credentials));
      onFailure();
    });
};

export const checkUserDetailsWithExpiry = async (
  userRepository: IUserRepository,
  dispatch: React.Dispatch<IAuthenticateUpdatePortalDetails>,
): Promise<'success' | 'expired' | 'error'> => {
  return await userRepository
    .checkUserDetails()
    .then(res => {
      if (res && res.success) {
        const expired = isLicenceExpired(res.licence.expires);
        if (expired) {
          return 'expired';
        } else {
          dispatch(UpdatePortalDetails(res.user, res.licence));
          return 'success';
        }
      } else {
        return 'error';
      }
    })
    .catch(() => {
      console.error('error in checkUserDetailsWithExpiry');
      return 'error';
    });
};

export const rememberMeSignInWithChecks = (
  userRepository: IUserRepository,
  onExpired: () => void,
  dispatch: React.Dispatch<
    IAuthenticateUpdatePortalDetails | IAuthenticateSignInSuccess
  >,
  onFailure: () => void,
  onSuccess: () => void,
) => {
  userRepository
    .checkUserDetails()
    .then(res => {
      if (res && res.success) {
        // check for expired licence first
        const expired = isLicenceExpired(res.licence.expires);
        if (expired) {
          onExpired();
        } else {
          onSuccess();
          rememberMeSignIn(dispatch, res.user, res.licence);
        }
      } else {
        console.error('failed to updated portal details in Login.ts');
        onFailure();
      }
    })
    .catch(() => {
      console.error('could not connect to portal to update details');
      onFailure();
    });
};

export const rememberMeSignIn = (
  dispatch: React.Dispatch<
    IAuthenticateUpdatePortalDetails | IAuthenticateSignInSuccess
  >,
  user: PortalUserDetails,
  licence: PortalLicenceDetails,
) => {
  dispatch(UpdatePortalDetails(user, licence));

  /*dispatch(
    actionSigninSuccess({
      token: 'token',
      licence: {
        ...licence,
        firstName: user.firstName,
        lastName: user.surname,
        trackingId: user.trackingId ?? '',
        registered: user.registered,
      },
    }),
  );*/
  dispatch(
    actionSigninSuccess(
      {
        ...licence,
        firstName: user.firstName,
        lastName: user.surname,
        trackingId: user.trackingId ?? '',
        registered: user.registered,
      },
      'token',
    ),
  );
};

export const isLicenceExpired = (expiryDate: string) => {
  if (Date.now() > Date.parse(expiryDate)) {
    return true;
  }
  return false;
};
