import { MessageBox } from '@estendio/presentpal-ui-kit';
import React, { useEffect, useState } from 'react';

export const ConnectionErrorMessage = () => {
  const [onLine, setOnLine] = useState(window.navigator.onLine);

  useEffect(() => {
    const handler = () => {
      setOnLine(window.navigator.onLine);
    };

    handler();

    window.addEventListener('online', handler);
    window.addEventListener('offline', handler);

    return () => {
      window.removeEventListener('online', handler);
      window.removeEventListener('offline', handler);
    };
  }, []);

  const errorMessage = (
    <div className="pt-2">
      <MessageBox
        message={
          'Server cannot be reached, please check your internet connection'
        }
        type="error"
      />
    </div>
  );

  return !onLine ? errorMessage : null;
};
