import React from 'react';

import { DraggableItem, DraggableItemProps } from './DraggableItem';
import { MdChevronRight } from 'react-icons/md';
import { Bullet } from '@estendio/presentpal-ui-kit';

export interface DraggableFlashcardItemProps
  extends Omit<DraggableItemProps, 'children'> {
  title: string;
  subtitle: string;
}

export function DraggableFlashcardItem(props: DraggableFlashcardItemProps) {
  const { title, subtitle, ...rest } = props;
  return (
    <DraggableItem {...rest}>
      <span className="p-4 pr-2 flex flex-col flex-1">
        <span className="block text-slate-900 pb-2">{`${rest.index +
          1}. ${title}`}</span>
        <span className="text-slate-900 text-sm flex items-center">
          <Bullet theme={rest.theme} />
          <span className="pl-2">{subtitle}</span>
        </span>
      </span>
      <span className="w-9">
        <MdChevronRight size={32} focusable="false" aria-hidden="true" />
      </span>
    </DraggableItem>
  );
}
