import { AmplitudeClient } from 'amplitude-js'; // "@types/amplitude-js": "4.4.5",
import {
  EventType,
  LoginEvent,
  CreateDeckEvent,
  PageViewEvent,
  AmplitudeEvent,
  PresentButtonEvent,
  AddBulletEvent,
  CreateFlashcardEvent,
} from './Events';

type logAmplitudeEventOverloadFn = {
  (instance: AmplitudeClient, type: EventType.Login, event: LoginEvent): void;
  (
    instance: AmplitudeClient,
    type: EventType.CreateDeck,
    event: CreateDeckEvent,
  ): void;
  (
    instance: AmplitudeClient,
    type: EventType.PageView,
    event: PageViewEvent,
  ): void;
  (
    instance: AmplitudeClient,
    type: EventType.PresentButton,
    event: PresentButtonEvent,
  ): void;
  (
    instance: AmplitudeClient,
    type: EventType.AddBullet,
    event: AddBulletEvent,
  ): void;
  (
    instance: AmplitudeClient,
    type: EventType.CreateFlashcard,
    event: CreateFlashcardEvent,
  ): void;
};

export const logAmplitudeEvent: logAmplitudeEventOverloadFn = (
  instance: AmplitudeClient,
  type: EventType,
  event: AmplitudeEvent,
) => {
  const { name, properties } = event;
  instance.logEvent(name, properties);
};
