import { IThunkDispatch } from '@estendio/presentpal-store';
import {
  AllDeckRepoCommands,
  IBulletCommands,
  IDeckCommands,
  IFlashcardCommands,
  IMessage,
  ITarget,
} from '@estendio/presentpal-rdm';
import handleBulletChanges from './bulletSubscriptionHandlers';
import handleDeckChanges from './deckSubscriptionHandlers';
import handleFlashcardChanges from './flashcardSubscriptionHandlers';

export default function handleChangesInDeckRepo(
  data: IMessage<AllDeckRepoCommands>,
  thunkDispatch: IThunkDispatch,
) {
  switch (data.target) {
    case ITarget.Deck:
      handleDeckChanges(data as IMessage<IDeckCommands>, thunkDispatch);
      break;
    case ITarget.Flashcard:
      handleFlashcardChanges(
        data as IMessage<IFlashcardCommands>,
        thunkDispatch,
      );
      break;
    case ITarget.Bullet:
      handleBulletChanges(data as IMessage<IBulletCommands>, thunkDispatch);
      break;
  }
}
