import { ValueValidator, ValidationMode } from '@fmtk/validation';
import { makeTrace } from '@pp/api-client';

export interface SlidesInfo {
  index: number;
  id: string;
  title: string;
}

const trace = makeTrace('service:office');

export interface OfficeHostInfo {
  host: Office.HostType;
  platform: Office.PlatformType;
}

export interface SlideInfo {
  id: string;
  title: string;
  index: number;
}

export enum ActiveView {
  Edit = 'edit',
  Read = 'read',
}

export type DialogMessage = '';

export interface DialogMessageHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (message: unknown, dialog: Office.Dialog): void;
}

export interface OfficeService {
  getActiveView(): PromiseLike<ActiveView>;
  getHostInfo(): OfficeHostInfo;
  getSelectedSlides(): PromiseLike<SlideInfo[]>;
  getSetting<T>(id: string, validator: ValueValidator<T>): T;
  goToSlide(index: number): PromiseLike<void>;
  messageParent(message: DialogMessage): void;
  on(
    event: 'activeViewChanged',
    handler: (value: ActiveView) => void,
  ): () => void;
  on(
    event: 'selectionChanged',
    handler: (value: SlideInfo[]) => void,
  ): () => void;
  openDialog(
    url: string,
    options: Office.DialogOptions,
    messageHandler?: DialogMessageHandler,
  ): void;

  setSetting<T>(
    id: string,
    validator: ValueValidator<T>,
    value: T,
  ): PromiseLike<void>;

  waitForReady(timeout?: number): PromiseLike<OfficeHostInfo>;

  getAllSlidesInfo(): Promise<SlidesInfo[] | undefined>;
}

interface SlideResult {
  slides: SlideInfo[];
}

export function makeOfficeService(): OfficeService {
  return {
    getActiveView,
    getHostInfo,
    getSelectedSlides,
    getSetting,
    goToSlide,
    messageParent,
    on,
    openDialog,
    setSetting,
    waitForReady,
    getAllSlidesInfo,
  };
}

function getActiveView(): PromiseLike<ActiveView> {
  return new Promise((resolve, reject) => {
    Office.context.document.getActiveViewAsync((result) => {
      trace({ msg: `Office: getActiveViewAsync`, result });

      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value as ActiveView);
      } else {
        reject(result.error || new Error(`getActiveViewAsync: failed`));
      }
    });
  });
}

function getHostInfo(): OfficeHostInfo {
  return {
    host: Office.context.host,
    platform: Office.context.platform,
  };
}

function goToSlide(index: number): PromiseLike<void> {
  return new Promise((resolve, reject) => {
    Office.context.document.goToByIdAsync(
      `${index}`, // cast to string to fix bug in windows Office; indexing starts from 1 not 0!!
      Office.GoToType.Index,
      (result) => {
        trace({ msg: `Office: goToByIdAsync`, result });

        if (result.status === Office.AsyncResultStatus.Succeeded) {
          resolve();
        } else {
          reject(result.error || new Error(`goToByIdAsync: failed`));
        }
      },
    );
  });
}

function getSelectedSlides(): PromiseLike<SlideInfo[]> {
  return new Promise((resolve, reject) => {
    Office.context.document.getSelectedDataAsync<SlideResult>(
      Office.CoercionType.SlideRange,
      (result) => {
        trace({ msg: `Office: getSelectedDataAsync`, result });
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          resolve(result.value.slides);
        } else {
          reject(result.error || new Error(`getSelectedDataAsync: failed`));
        }
      },
    );
  });
}

function getSetting<T>(id: string, validator: ValueValidator<T>): T {
  const value = validateSettings(
    Office.context.document.settings.get(id),
    validator,
    true,
  );
  trace({ msg: `Office: getSetting`, id, value });
  return value;
}

function messageParent(message: unknown): void {
  const messageStr =
    typeof message === 'string' ? message : JSON.stringify(message);
  Office.context.ui.messageParent(messageStr);
}

// Removed redeclarations to adhere to ESLint no-redeclare rule.
function on(event: string, handler: (value: any) => void): () => void {
  switch (event) {
    case 'activeViewChanged': {
      const wrappedHandler = (result: Office.AsyncResult<void>) => {
        trace({ msg: `Office: addHandlerAsync.ActiveViewChanged`, result });
        getActiveView().then(handler, (err) => {
          console.error(err);
        });
      };

      Office.context.document.addHandlerAsync(
        Office.EventType.ActiveViewChanged,
        wrappedHandler,
      );
      return () => {
        Office.context.document.removeHandlerAsync(
          Office.EventType.ActiveViewChanged,
          wrappedHandler,
        );
      };
    }

    case 'selectionChanged': {
      const wrappedHandler = (result: Office.AsyncResult<void>) => {
        trace({
          msg: `Office: addHandlerAsync.DocumentSelectionChanged`,
          result,
        });

        getSelectedSlides().then(handler, (err) => {
          console.error(err);
        });
      };

      Office.context.document.addHandlerAsync(
        Office.EventType.DocumentSelectionChanged,
        wrappedHandler,
      );
      return () => {
        Office.context.document.removeHandlerAsync(
          Office.EventType.DocumentSelectionChanged,
          wrappedHandler,
        );
      };
    }
  }

  throw new Error(`unknown event ${event}`);
}

function openDialog(
  url: string,
  options: Office.DialogOptions,
  messageHandler?: DialogMessageHandler,
): void {
  Office.context.ui.displayDialogAsync(url, options, (result) => {
    if (result.status !== Office.AsyncResultStatus.Succeeded) {
      return;
    }

    if (messageHandler) {
      const dialog = result.value;

      dialog.addEventHandler(
        Office.EventType.DialogMessageReceived,
        (event: any) => {
          const message = JSON.parse(event.message);
          messageHandler(message, dialog);
        },
      );
    }
  });
}

function setSetting<T>(
  id: string,
  validator: ValueValidator<T>,
  value: T,
): PromiseLike<void> {
  trace({ msg: `Office: setSetting`, id, value });
  Office.context.document.settings.set(id, validateSettings(value, validator));

  return new Promise((resolve, reject) => {
    Office.context.document.settings.saveAsync((result) => {
      trace({ msg: `Office: settings.saveAsync`, result });

      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve();
      } else {
        reject(result.error || new Error(`getSelectedDataAsync: failed`));
      }
    });
  });
}

function waitForReady(
  timeout: number | undefined = 5000,
): Promise<OfficeHostInfo> {
  return new Promise((resolve, reject) => {
    if (timeout) {
      setTimeout(
        () => reject(new Error(`timeout while waiting for Office to load`)),
        timeout,
      );
    }
    Office.onReady((info) => {
      if (!info || !info.host || !info.platform) {
        reject(new Error(`not running in office`));
      } else {
        resolve(info);
      }
    });
  });
}

function validateSettings<T>(
  value: unknown,
  validator: ValueValidator<T>,
  input = false,
): T {
  const validation = validator({
    value,
    mode: input ? ValidationMode.JSON : ValidationMode.Strict,
  });

  if (!validation.ok) {
    trace({
      msg: `Office: settings validation failure`,
      err: [],
    });
    throw new Error(`Office: settings validation failure`);
  }

  return validation.value;
}

async function getTitle(context: any, textRange: any) {
  //Handles the scenario when there is no title in the slide
  return context
    .sync()
    .then(() => textRange.text)
    .catch(() => '')
    .finally(() => '');
}

async function getSlideTextObject(context: any, slide: any) {
  //Get slide object
  slide.load('shapes');
  return context
    .sync()
    .then(() => {
      //Get teh first shape object with text frame
      const shapes = slide.shapes;
      const shape0 = shapes.getItemAt(0);
      const textRange = shape0.textFrame.textRange;
      return textRange;
    })
    .catch(() => null)
    .finally(() => null);
}

async function getAllSlidesInfo(): Promise<SlidesInfo[] | undefined> {
  // Only supported by latest API versions
  if (Office.context.requirements.isSetSupported('PowerPointApi', '1.2')) {
    try {
      return await PowerPoint.run(async function (context: any) {
        const slides = context.presentation.slides;
        slides.load();
        await context.sync();

        const slidesInfo = [];
        for (let i = 0; i < slides.items.length; i++) {
          let slide = slides.getItemAt(i);
          //PowerPoint API requires context update whenever you are loading a new object
          await context.sync();

          //Empty strings matches return for empty slide title
          //For older APIs we want ot return empty string for backwards compatibility
          let title = '';
          if (
            Office.context.requirements.isSetSupported('PowerPointApi', '1.4')
          ) {
            //Get slide object
            slide.load('shapes');
            await context.sync();

            const textRange = await getSlideTextObject(context, slide);
            if (textRange !== null) {
              textRange.load();
              title = await getTitle(context, textRange);
            }
          }

          slidesInfo.push({
            index: i + 1, //Matches PP API where indexing starts from 1
            id: slide.id,
            title,
          });
        }

        await context.sync();
        return slidesInfo;
      });
    } catch (e) {
      console.log('Error getting slides info:', e);
      return Promise.reject('Error getting slides info');
    }
  } else {
    return Promise.resolve(undefined);
  }
}
