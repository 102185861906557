import React, { useCallback, useContext, useEffect } from 'react';
import { createContext, useMemo, useState } from 'react';

import {
  ActiveView,
  makeOfficeService,
  OfficeHostInfo,
  OfficeService,
  SlideInfo,
} from '../services/OfficeService/OfficeService';

interface OfficeContextProps {
  officeService: OfficeService;
  host: Office.HostType | null;
  platform: Office.PlatformType | null;
  slideInfo: SlideInfo[] | null;
  activeView: ActiveView | null;
  isPresenting: boolean;
}

export interface OfficeContextProviderProps {
  children: React.ReactNode;
}

export const OfficeContext = createContext<OfficeContextProps>({} as any);

export const OfficeContextProvider = ({
  children,
}: OfficeContextProviderProps) => {
  const [hosting, setHosting] = useState<OfficeHostInfo | null>(null);
  const [slideInfo, setSlideInfo] = useState<SlideInfo[] | null>(null);
  const [activeView, setActiveView] = useState<ActiveView | null>(null);
  const officeService: OfficeService = makeOfficeService();

  const onSlideChanged = useCallback(
    (value: SlideInfo[]) => setSlideInfo(value),
    [],
  );

  const onActiveViewChanged = useCallback(
    (view: ActiveView) => {
      if (view !== activeView) {
        setActiveView(view);
      }
    },
    [activeView],
  );

  const initOffice = useCallback(async () => {
    try {
      const host = await officeService.waitForReady(5000);

      if (host) {
        setHosting(host);
      }
    } catch (e) {
      console.log(e);
    }
  }, [officeService]);

  useEffect(() => {
    if (!hosting) {
      initOffice();
    }
  }, [hosting, initOffice]);

  useEffect(() => {
    let cancelActiveViewListener: () => void;
    let cancelSlideChangeListener: () => void;
    if (hosting) {
      cancelActiveViewListener = officeService.on(
        'activeViewChanged',
        onActiveViewChanged,
      );
      cancelSlideChangeListener = officeService.on(
        'selectionChanged',
        onSlideChanged,
      );
    }
    return () => {
      if (cancelActiveViewListener) {
        cancelActiveViewListener();
      }
      if (cancelSlideChangeListener) {
        cancelSlideChangeListener();
      }
    };
  }, [hosting, officeService, onActiveViewChanged, onSlideChanged]);

  const props: OfficeContextProps = useMemo(() => {
    return {
      officeService,
      host: hosting?.host ?? null,
      platform: hosting?.platform ?? null,
      slideInfo,
      activeView,
      isPresenting: activeView === ActiveView.Read,
    };
  }, [activeView, hosting, officeService, slideInfo]);

  return (
    <OfficeContext.Provider value={props}>{children}</OfficeContext.Provider>
  );
};

export function useOfficeService(): OfficeService {
  const value = useContext(OfficeContext);
  if (!value) {
    throw new Error(
      `no instance found for OfficeService (have you included OfficeServiceProvider?)`,
    );
  }
  return value.officeService;
}
