import { IBullet } from '@estendio/presentpal-store';
import { IDeckRepository } from '../../repository/deck';

export const editBullet = async (
  deckRepository: IDeckRepository,
  id: string,
  deckId: string,
  update: Partial<IBullet>,
  flashcardId: string,
) => {
  await deckRepository?.updateBullet(deckId, flashcardId, id, update);
};
