export const getFormattedDate = (date: number, withHM = false) => {
  const createdDate = new Date(date);
  let options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  if (withHM) {
    options = { ...options, ...{ hour: '2-digit', minute: '2-digit' } };
  }
  const formattedDate = withHM
    ? createdDate.toLocaleString('en-us', options)
    : createdDate.toLocaleDateString('en-us', options);
  return `${formattedDate}`;
};
