import {
  Input,
  USER_THEMES,
  MessageBox,
  ButtonPrimary,
  Placeholder,
} from '@estendio/presentpal-ui-kit';
import React, { FC, useContext, useState } from 'react';
import { MdSend } from 'react-icons/md';
import PageWithNav from '../../components/pageTemplates/PageWithNav';
import { Routes } from '../../components/routing/types';
import { checkEmail } from '../../helpers/login/login';
import useNavigation from '../../hooks/navigationHook';
import { ForgotPasswordProps } from './types';
import ForgotPasswordImage from '../../assets/forgot.svg';
import SentImage from '../../assets/sent.svg';
import { RepositoryContext } from '../../context/RepositoriesContext';
import { Loading } from '../loading/Loading';

const ForgotPassword: FC<ForgotPasswordProps> = ({ loginEmail }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(loginEmail);
  const [errors, setErrors] = useState({ emailError: '', genericError: '' });
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const navigateTo = useNavigation();

  const { userRepository } = useContext(RepositoryContext);

  const handleBack = () => {
    navigateTo(Routes.Login, undefined);
  };

  const validateEmail = (value: string) => {
    const emailErrorCheck = checkEmail(value);
    if (emailErrorCheck.error) {
      setErrors({
        ...errors,
        emailError: emailErrorCheck.error,
      });
    } else {
      setValidEmail(true);
    }
    return emailErrorCheck;
  };

  const submit = async () => {
    const checkedEmail = validateEmail(email);
    if (checkedEmail.error) {
      return;
    }
    setLoading(true);
    const sent = await userRepository.forgotPassword(checkedEmail.trimmedInput);
    if (sent) {
      setSubmitted(true);
    } else {
      setErrors({
        ...errors,
        genericError: 'Error sending request. Please try again',
      });
    }
    setLoading(false);
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    submit();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
    setValidEmail(false);
  };

  const formContent = (
    <>
      <Placeholder
        imageSrc={ForgotPasswordImage}
        imagePlacement="bottom"
        height="max-h-36"
        headerText="Forgotten your password?"
        subtitle="Enter your email address below to receive password reset instructions."
        headerHtmlTag="h1"
      />
      <form onSubmit={handleOnSubmit}>
        <div className="mb-4 mt-8">
          <Input
            onChange={onChange}
            onFocus={() => {
              setErrors({ ...errors, emailError: '', genericError: '' });
              setValidEmail(false);
            }}
            type="text"
            id="email"
            error={errors.emailError}
            label="Email address"
            helpText="Example: yourname@email.com"
            isValid={validEmail}
            value={email}
            onBlur={e => validateEmail(e.currentTarget.value)}
          />
        </div>

        {errors.genericError ? (
          <MessageBox message={errors.genericError} type="error" />
        ) : null}
        <div className="my-4">
          <ButtonPrimary
            label="Send instructions"
            fullWidth
            onClick={submit}
            theme={USER_THEMES.PRIMARY}
            icon={<MdSend />}
          />
        </div>
      </form>
    </>
  );

  const afterSubmittingContent = (
    <>
      <Placeholder
        imageSrc={SentImage}
        height="max-h-36"
        imagePlacement="bottom"
        headerText="Email sent"
        subtitle="Please check your inbox for reset instructions"
        headerHtmlTag="h1"
      />
    </>
  );

  return (
    <PageWithNav
      navOnClick={handleBack}
      navLabel="Forgot Password"
      hasFooter={false}
    >
      {loading ? (
        <Loading fullScreen />
      ) : submitted ? (
        afterSubmittingContent
      ) : (
        formContent
      )}
    </PageWithNav>
  );
};

export default ForgotPassword;
