import axios from 'axios';
import * as Auth from '@estendio/presentpal-auth';
import {
  IAwsClient,
  IPortalClient,
  IAwsApiCredentials,
  UpdateSubscriber,
  UserApiJwtCredentials,
  PortalUpdateSubscriber,
  Local,
  SessionStorage,
  ISessionStorage,
  IUserClient,
} from '@estendio/presentpal-auth';

import DeckRepository, { IDeckRepository } from './deck';
import UserRepository, { IUserRepository } from './user';
import { IThunkDispatch } from '@estendio/presentpal-store';
import PresentationRepository, {
  IPresentationRepository,
} from './presentation';

export interface IAwsOptions {
  url: string;
  credentials?: Promise<IAwsApiCredentials>;
  credentialsUpdate?: UpdateSubscriber;
}

export interface IPortalOptions {
  url: string;
  credentials?: Promise<UserApiJwtCredentials>;
  credentialsUpdate?: PortalUpdateSubscriber;
  version?: number;
}

export interface UserRepositoryConfig {
  portalOptions: IPortalOptions;
  awsOptions: IAwsOptions;
}

export interface DeckRepositoryConfig {
  awsOptions: IAwsOptions;
  dispatch: IThunkDispatch;
}

export interface PresentationRepositoryConfig {
  awsOptions: IAwsOptions;
}

export interface AllRepositoryConfig {
  user: UserRepositoryConfig;
  deck: DeckRepositoryConfig;
  presentation: PresentationRepositoryConfig;
}

export interface AllRepositoryResult {
  user: IUserRepository;
  deck: IDeckRepository;
  presentation: IPresentationRepository;
}

const createStorageManager = () => {
  const local = new Local();
  const session = new Local(); // changed to fix remember me as some of the remember me info was being lost in session
  return new SessionStorage(local, session);
};

const clients: {
  aws: { [url: string]: IAwsClient };
  portal: { [url: string]: IPortalClient };
  storage: ISessionStorage;
} = { aws: {}, portal: {}, storage: createStorageManager() };

export const createAwsClient = (options: IAwsOptions) => {
  if (!clients.aws[options.url]) {
    const axiosConfig = Auth.createAxiosConfig(options.url);
    const axiosClient = axios.create(axiosConfig);
    clients.aws[options.url] = new Auth.CloudDeckStorageClient({
      // @ts-ignore
      client: axiosClient,
      credentials: options.credentials,
      credentialsUpdate: options.credentialsUpdate,
      sessionManager: clients.storage,
    });
  }

  return clients.aws[options.url];
};

export const createPortalClient = (options: IPortalOptions) => {
  const clientKey = options.url;

  if (!clients.portal[clientKey]) {
    const axiosConfig = Auth.createPortalAxiosConfig(options.url);
    const axiosClient = axios.create(axiosConfig);
    // TODO types error in original code need fixing
    // @ts-ignore
    clients.portal[clientKey] = new Auth.PortalClient(
      // @ts-ignore
      axiosClient,
      clients.storage,
    );
  }

  return clients.portal[clientKey];
};

export function setupUserRespository(
  config: UserRepositoryConfig,
): IUserRepository {
  const portalClient = createPortalClient(config.portalOptions);
  const awsClient = createAwsClient(config.awsOptions);

  const userClient: IUserClient = new Auth.UserClient({
    portalClient,
    awsClient,
    storageClient: clients.storage,
  });

  const userRepos = new UserRepository(userClient);

  return userRepos;
}

export function setupDeckRespository(
  config: DeckRepositoryConfig,
): IDeckRepository {
  const awsClient = createAwsClient(config.awsOptions);
  const deckRepos = new DeckRepository(awsClient, config.dispatch);

  return deckRepos;
}

export function setupPresentationRespository(
  config: PresentationRepositoryConfig,
): IPresentationRepository {
  const awsClient = createAwsClient(config.awsOptions);
  const presentationRepos = new PresentationRepository(awsClient);

  return presentationRepos;
}

export default function setupRepositories(
  config: AllRepositoryConfig,
): AllRepositoryResult {
  return {
    user: setupUserRespository(config.user),
    deck: setupDeckRespository(config.deck),
    presentation: setupPresentationRespository(config.deck),
  };
}
