import React, { useState } from 'react';
import { IBullet, HintType } from '@estendio/presentpal-store';
import ContentDefault from './ContentDefault';
import ContentImageSafetyNet from './ContentImageSafetyNet';
import ContentTextSafetyNet from './ContentTextSafetyNet';

export default function Content({
  bullet,
  deckId,
  flashcardId,
}: {
  bullet: IBullet;
  deckId: string;
  flashcardId: string;
}) {
  const [openTextSafetyNetEdit, setOpenTextSafetyNetEdit] = useState(false);
  const [openImageSafetyNetEdit, setOpenImageSafetyNetEdit] = useState(false);

  const getView = () => {
    if (
      openTextSafetyNetEdit ||
      (!!bullet.hint && bullet.hint?.type === HintType.Text)
    ) {
      return (
        <ContentTextSafetyNet
          bullet={bullet}
          deckId={deckId}
          flashcardId={flashcardId}
        />
      );
    } else if (
      openImageSafetyNetEdit ||
      (!!bullet.hint && bullet.hint?.type === HintType.Image)
    ) {
      return (
        <ContentImageSafetyNet
          bullet={bullet}
          flashcardId={flashcardId}
          deckId={deckId}
        />
      );
    }
    return (
      <ContentDefault
        setOpenTextSafetyNetEdit={setOpenTextSafetyNetEdit}
        setOpenImageSafetyNetEdit={setOpenImageSafetyNetEdit}
      />
    );
  };

  return <div>{getView()}</div>;
}
