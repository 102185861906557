import {
  actionResetDecks,
  ResetDecksAction,
  actionResetAllStatus,
  ResetAllStatusAction,
  actionAuthenticationChangeBegin,
  actionAuthenticationChanged,
  actionSignoutBegin,
  actionSignoutComplete,
  IAuthenticateSignoutBegin,
  IAuthenticateSignoutComplete,
  IAuthenticationChangeBeginAction,
  IAuthenticationChangedAction,
} from '@estendio/presentpal-store';
import { IUserRepository } from '../../repository/user';

export const logout = (
  userRepository: IUserRepository,
  dispatch: React.Dispatch<
    | IAuthenticateSignoutBegin
    | IAuthenticationChangeBeginAction
    | ResetDecksAction
    | IAuthenticateSignoutComplete
    | IAuthenticationChangedAction
    | ResetAllStatusAction
  >,
) => {
  dispatch(actionAuthenticationChangeBegin());
  dispatch(actionSignoutBegin());
  dispatch(actionResetDecks());
  dispatch(actionResetAllStatus());
  dispatch(actionSignoutComplete());
  dispatch(actionAuthenticationChanged());
  userRepository.logOut();
};
