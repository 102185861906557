import React, { useContext, useEffect } from 'react';

import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import AuthWrapper from './pages/authWrapper/AuthWrapper';
import SafetyNet from './pages/safetyNet/SafetyNet';

import { Routes } from './components/routing/types';
import { Loading } from './pages/loading/Loading';
import { RouteContext } from './context/RouteContext';
import { IUserState } from '@estendio/presentpal-store';
import { useTypedSelector, IStoreState } from '@estendio/presentpal-store';
import DeckList from './pages/deckList/DeckList';
import EditDeck from './pages/editDeck/EditDeck';
import EditFlashcard from './pages/editFlashcard/EditFlashcard';

export function App() {
  const { route, props, setRoute } = useContext(RouteContext);

  const user: IUserState = useTypedSelector((state: IStoreState) => {
    return state.user;
  });

  useEffect(() => {
    if (!user.authenticated && route !== Routes.ForgotPassword) {
      setRoute(Routes.Login, undefined);
    }
  }, [route, setRoute, user.authenticated]);

  let page;
  if (!user.authenticated) {
    // NON-AUTH ROUTES
    switch (route) {
      case Routes.Login:
        page = <AuthWrapper withLoginForm />;
        break;
      case Routes.ForgotPassword:
        page = <ForgotPassword {...props} />;
        break;
      default:
        page = <Loading fullScreen />;
    }
  } else {
    // AUTH ROUTES
    switch (route) {
      case Routes.EditDeck:
        page = <EditDeck {...props} />;
        break;
      case Routes.EditFlashcard:
        page = <EditFlashcard {...props} />;
        break;
      case Routes.SafetyNet:
        page = <SafetyNet {...props} />;
        break;
      case Routes.DeckList:
        page = <DeckList />;
        break;
      default:
        page = <AuthWrapper />;
    }
  }

  return page;
}
