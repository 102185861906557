import { useContext } from 'react';
import { RoutePropList, Routes } from '../components/routing/types';
import { RouteContext } from '../context/RouteContext';

export default function useNavigation() {
  const { setRoute } = useContext(RouteContext);
  function navigate<R extends Routes>(route: R, params: RoutePropList[R]) {
    return setRoute(route, params);
  }
  return navigate;
}
