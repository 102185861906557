import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';

import './index.css';
import { OfficeContextProvider } from './context/OfficeContext';

import { AmplitudeProvider } from './amplitude/AmplitudeService';
import { RouteContextProvider } from './context/RouteContext';
import { Provider as StoreProvider } from 'react-redux';
import store, { storePersist, thunkDispatch } from './redux/store';
import setupRepositories from './repository';
import { PersistGate } from 'redux-persist/integration/react';
import RepositoryProvider from './context/RepositoriesContext';
import AppWrapper from './AppWrapper';
import { getConfig } from './config';

const container = document.getElementById('root');
const root = createRoot(container!);
// when env is staging or prod, the url can come from the config set in cdk.ts, but for local dev, this doesn't work
const portalOptions = {
  url:
    process.env.NODE_ENV === 'development'
      ? 'https://localhost'
      : getConfig().portal,
};
const awsOptions = {
  url:
    process.env.NODE_ENV === 'development'
      ? 'https://localhost:6002/api'
      : getConfig().api,
};

const repositories = setupRepositories({
  user: {
    portalOptions,
    awsOptions,
  },
  deck: {
    awsOptions,
    dispatch: thunkDispatch,
  },
  presentation: {
    awsOptions,
  },
});

root.render(
  <OfficeContextProvider>
    <AmplitudeProvider>
      <StoreProvider store={store}>
        <PersistGate loading={null} persistor={storePersist}>
          <RepositoryProvider
            userRepository={repositories.user}
            deckRepository={repositories.deck}
            presentationRepository={repositories.presentation}
          >
            <RouteContextProvider>
              <AppWrapper />
            </RouteContextProvider>
          </RepositoryProvider>
        </PersistGate>
      </StoreProvider>
    </AmplitudeProvider>
  </OfficeContextProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
