import classNames from 'classnames';
import React, { FC } from 'react';
import { MdMessage } from 'react-icons/md';
import { IconButton, Bullet, USER_THEMES } from '@estendio/presentpal-ui-kit';
import { DraggableItem } from './DraggableItem';

export interface Props {
  id: string;
  index: number;
  content: string;
  hasSafetyNet: boolean;
  onSafetyNetClick: () => void;
  onClick: () => void;
  isDragDisabled?: boolean;
  theme: USER_THEMES;
}

export const BulletButton: FC<Props> = ({
  id,
  index,
  content,
  theme,
  hasSafetyNet,
  onSafetyNetClick,
  onClick,
  isDragDisabled,
}) => {
  const containerClasses = classNames(
    `input-container relative w-full rounded-lg shadow`,
    `bg-${theme}-200 hover:bg-${theme}-300 active:bg-${theme}-100`,
  );

  const bulletButtonClasses = classNames(
    'bg-transparent flex w-full rounded-lg',
    {
      'mr-12': hasSafetyNet,
    },
  );

  const textClasses = classNames(
    `text-left text-base font-sans rounded-lg pr-10 pl-7 py-4`,
  );

  const noTextClasses = textClasses + ' invisible';

  const bulletClasses = classNames('absolute top-3 pt-0.5 pl-3', {});

  const iconButtonClasses = classNames(
    'absolute top-1/2  transform -translate-y-1/2 right-1',
  );

  const srBulletContent = content ? content : 'Empty bullet';

  const textContent = (
    <>
      <span className={bulletClasses}>
        <Bullet theme={theme} />
      </span>
      {content ? (
        <p className={textClasses}>{content}</p>
      ) : (
        // this is not seen, but is used to make the container the same height as when content is there.
        // if we swap to a placeholder, this can be removed
        <p aria-hidden={true} className={noTextClasses}>
          {srBulletContent}
        </p>
      )}
    </>
  );

  const safetyNetLabel = 'Edit safety net';
  const safetyNetIcon = hasSafetyNet && (
    <span className={iconButtonClasses}>
      <abbr title={safetyNetLabel}>
        <IconButton
          theme={theme}
          onClick={onSafetyNetClick}
          ariaLabel={safetyNetLabel}
          iconSize="lg"
          icon={<MdMessage />}
        />
      </abbr>
    </span>
  );

  if (!isDragDisabled) {
    return (
      <DraggableItem
        id={id}
        index={index}
        theme={theme}
        isDragDisabled={false}
        onItemClick={onClick}
        ariaLabel={`${srBulletContent}. Click to edit or delete`}
      >
        <span className="w-full">
          <span className={bulletButtonClasses}>{textContent}</span>
        </span>
        {safetyNetIcon}
      </DraggableItem>
    );
  }
  return (
    <div className={containerClasses}>
      <button
        aria-label={`${srBulletContent}. Click to edit or delete`}
        className={bulletButtonClasses}
        onClick={onClick}
      >
        {textContent}
      </button>
      {safetyNetIcon}
    </div>
  );
};
