import { IAwsClient, IPresentationData } from '@estendio/presentpal-auth';
import {
  IAwsStartingSessionSuccess,
  IAwsStartingSessionFailure,
} from '@estendio/presentpal-auth/dist/cloudDeckStorage/types';
import { IPresentationManager } from '@estendio/presentpal-rdm';
import { getOS } from './helpers';

export interface IPresentationRepository {
  startSession(
    token: string,
    presentationId: string,
    presentationTitle: string,
  ): Promise<IAwsStartingSessionSuccess | IAwsStartingSessionFailure>;
  setPresentationManager(presentationManager: IPresentationManager): void;
  myPresentationManager: IPresentationManager | null;
}

export default class PresentationRepository implements IPresentationRepository {
  private myClient: IAwsClient;
  myPresentationManager: IPresentationManager | null = null;

  constructor(awsClient: IAwsClient) {
    this.myClient = awsClient;
  }

  // This code has been tweaked compared to the one in add on...
  async startSession(
    token: string,
    presentationId: string,
    presentationTitle: string,
  ) {
    const OS = getOS();
    const presentationType = 'PowerPoint';
    const description = `${presentationType} on ${OS === 'Mac' ? 'Mac' : 'PC'}`;
    const presentationData: IPresentationData = {
      description,
      presentationId,
      presentationTitle,
      presentationType,
      presentationStatus: 'active',
      OS: OS ?? 'unknown operator',
    };
    let wss: IAwsStartingSessionFailure | IAwsStartingSessionSuccess = {
      ok: false,
      status: -1,
      error: '',
      errorDetails: [
        {
          id: '',
          text: '',
          field: '',
        },
      ],
    };
    try {
      wss = await this.myClient.startSession(token, presentationData);
    } catch (e) {
      console.log('error starting wss ', e);
    }
    return wss;
  }

  setPresentationManager(presentationManager: IPresentationManager) {
    this.myPresentationManager = presentationManager;
  }
}
