import React, { FocusEvent, useContext, useRef, useState } from 'react';
import { MdDeleteForever, MdMessage, MdAddComment } from 'react-icons/md';
import {
  USER_THEMES,
  ButtonTertiary,
  BulletInput,
} from '@estendio/presentpal-ui-kit';
import { BulletButton } from './BulletButton';
import {
  BULLET_CHARS_COUNTDOWN_START,
  getInitialBulletValue,
  MAX_LENGTH_BULLET,
} from '../../helpers/editFlashcard/editFlashcard';
import { IBullet } from '@estendio/presentpal-store';
import { RepositoryContext } from '../../context/RepositoriesContext';
import { DEBOUNCE_DELAY } from '../../pages/editFlashcard/values';
import { debounce } from 'lodash';

export interface BulletListItemProps {
  bullet: IBullet;
  index: number;
  onSafetyNetClick: (bullet: IBullet) => void;
  deckId: string;
  flashcardId: string;
  editing: boolean;
  setEditing: (id: string) => void;
  onDeleteClick: () => void;
  isDragDisabled: boolean;
}

export const DraggableBulletListItem: React.FC<BulletListItemProps> = props => {
  const {
    bullet,
    index,
    onSafetyNetClick,
    deckId,
    flashcardId,
    editing,
    setEditing,
    onDeleteClick,
    isDragDisabled,
  } = props;

  const { deckRepository } = useContext(RepositoryContext);

  const [inputValue, setInputValue] = useState<string>(
    getInitialBulletValue(bullet),
  );

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = e.currentTarget.value;
    const slicedVal =
      val.length <= MAX_LENGTH_BULLET ? val : val.slice(0, MAX_LENGTH_BULLET);
    setInputValue(slicedVal);
    debouncedTitleSave(slicedVal);
  };

  const handleBulletClick = () => {
    setEditing(bullet.id);
  };
  const handleEnterHit = () => {
    setEditing('');
  };

  const handleBulletEdit = (content: string) => {
    deckRepository.updateBullet(deckId, flashcardId, bullet.id, {
      content,
    });
  };

  const debouncedTitleSave = useRef(debounce(handleBulletEdit, DEBOUNCE_DELAY))
    .current;

  const hasSafetyNet = !!bullet.hint;
  const handleSafetyNetClick = () => onSafetyNetClick(bullet);

  const handleOnFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    //Puts the cursor at the end of the input text on focus
    const element = e.target;
    element.selectionStart = element.value.length;
  };

  const getNonEditableBullet = (isDragDisabled: boolean) => (
    <BulletButton
      id={bullet.id}
      index={index}
      hasSafetyNet={hasSafetyNet}
      onClick={handleBulletClick}
      content={bullet.content}
      onSafetyNetClick={handleSafetyNetClick}
      isDragDisabled={isDragDisabled}
      theme={USER_THEMES.PRIMARY}
    />
  );

  if (!isDragDisabled) {
    return getNonEditableBullet(isDragDisabled);
  }

  return (
    // mb-3 needed to match drag and drop style
    <span className="block mb-3">
      {!editing ? (
        getNonEditableBullet(isDragDisabled)
      ) : (
        <>
          <BulletInput
            onChange={onChange}
            onFocus={handleOnFocus}
            theme={USER_THEMES.PRIMARY}
            value={inputValue}
            id={bullet.id}
            hasSafetyNet={hasSafetyNet}
            onSafetyNetClick={handleSafetyNetClick}
            ariaLabel="Bullet content"
            currentLength={inputValue.length}
            maxLength={MAX_LENGTH_BULLET}
            countdownFrom={BULLET_CHARS_COUNTDOWN_START}
            onEnterHit={handleEnterHit}
          />
          <div className="flex row justify-between">
            <ButtonTertiary
              theme={USER_THEMES.PRIMARY}
              icon={<MdDeleteForever />}
              label="Delete"
              onClick={onDeleteClick}
              buttonSize={'sm'}
            />
            <ButtonTertiary
              theme={USER_THEMES.PRIMARY}
              icon={hasSafetyNet ? <MdMessage /> : <MdAddComment />}
              label={hasSafetyNet ? 'Edit safety net' : 'Add safety Net'}
              onClick={handleSafetyNetClick}
              buttonSize={'sm'}
            />
          </div>
        </>
      )}
    </span>
  );
};
