import React, { useContext } from 'react';
import { USER_THEMES } from '@estendio/presentpal-ui-kit';

import { IFlashCard } from '@estendio/presentpal-store';
import { getAriaLabel, getSubtitle } from '../../helpers/editDeck/editDeck';
import { RepositoryContext } from '../../context/RepositoriesContext';
import { DraggableFlashcardItem } from '../draggable/DraggableFlashacrdItem';
import { DraggableList } from '../draggable/DraggableList';
import { useOfficeService } from '../../context/OfficeContext';
import { getFinalTitleValue } from '../../helpers/InputHelper';

export default function List({
  deckId,
  flashcards,
  handleSetRouteToEditFlashcard,
}: {
  deckId: string;
  flashcards: IFlashCard[];
  handleSetRouteToEditFlashcard: (
    flashcardId: string,
    flashcardIndex: number,
  ) => void;
}) {
  const { deckRepository } = useContext(RepositoryContext);
  const officeService = useOfficeService();

  const renderItem = (flashcard: IFlashCard, index: number) => {
    const bulletsTotal = flashcard.bullets.length;
    const subtitle = getSubtitle(bulletsTotal);
    const ariaLabel = getAriaLabel(index, flashcard.title, bulletsTotal);

    const handleOnClick = () => {
      officeService
        .getAllSlidesInfo()
        .then(slides => {
          if (slides && index < slides.length) {
            officeService.goToSlide(index + 1);
          }
        })
        .catch(e =>
          console.log('Error getting slides info on Edit Deck page:', e),
        )
        .finally(() => handleSetRouteToEditFlashcard(flashcard.id, index));
    };

    return (
      <DraggableFlashcardItem
        id={flashcard.id}
        title={getFinalTitleValue(flashcard.title)}
        subtitle={subtitle}
        index={index}
        theme={USER_THEMES.PRIMARY}
        onItemClick={handleOnClick}
        ariaLabel={ariaLabel}
        key={flashcard.id}
      />
    );
  };

  const updateFlashcardIndex = (
    draggableId: string,
    sourceIndex: number,
    destinationIndex: number,
  ) => {
    const positionDiff = destinationIndex - sourceIndex;
    if (draggableId && positionDiff) {
      deckRepository.moveFlashCard(deckId, draggableId, positionDiff);
    }
  };

  return (
    <DraggableList
      id="flashcards list"
      items={flashcards}
      renderItem={renderItem}
      onItemDrag={updateFlashcardIndex}
    />
  );
}
