import {
  ButtonTertiary,
  Card,
  ImageDropZone,
  MessageBox,
  USER_THEMES,
} from '@estendio/presentpal-ui-kit';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { RepositoryContext } from '../../context/RepositoriesContext';
import { IBullet, HintType, IHint } from '@estendio/presentpal-store';
import { editBullet } from '../../helpers/safetyNet/safetyNet';
import { VscGoToFile } from 'react-icons/vsc';

export default function ContentImageSafetyNet({
  bullet,
  deckId,
  flashcardId,
}: {
  bullet: IBullet;
  deckId: string;
  flashcardId: string;
}) {
  const { deckRepository } = useContext(RepositoryContext);
  const [loadingImageUpload, setLoadingImageUpload] = useState<boolean>(false);

  const [showImageDropZone, setShowImageDropZone] = useState<boolean | null>(
    null,
  );
  const [signedImageUrl, setSignedImageUrl] = useState<string>('');
  const [currentFile, setCurrentFile] = useState<FileWithPath | null>(null);
  const [error, setError] = useState('');
  const [imageUploadSuccess, setImageUploadSuccess] = useState('');

  const handleFileSave = async (file: FileWithPath) => {
    setLoadingImageUpload(true);
    const url = await deckRepository.uploadWebFile(file);
    if (url) {
      editBullet(
        deckRepository,
        bullet.id,
        deckId,
        {
          hint: { type: HintType.Image, content: url },
        },
        flashcardId,
      );

      signImage({ type: HintType.Image, content: url });
      setShowImageDropZone(false);
      setImageUploadSuccess('Image upload complete');
    } else {
      setImageUploadSuccess('');
      setError('Error uploading file');
    }
    setLoadingImageUpload(false);
  };

  const signImage = useCallback(
    async (hint: IHint) => {
      const signed = await deckRepository.signImage(hint);
      if (signed) {
        setSignedImageUrl(signed);
      }
    },
    [deckRepository],
  );

  const handleSwapImageClick = () => {
    setShowImageDropZone(true);
    setCurrentFile(null);
    setImageUploadSuccess('');
  };

  useEffect(() => {
    if (!bullet.hint) {
      return;
    }
    if (bullet.hint.type === HintType.Image) {
      signImage(bullet.hint);
    }
  }, [bullet, signImage]);

  return (
    <>
      {signedImageUrl && !showImageDropZone ? (
        <>
          <Card theme={USER_THEMES.PRIMARY}>
            <div className="items-center text-center">
              <img src={signedImageUrl} alt="" className="max-h-72" />
            </div>
          </Card>
          {imageUploadSuccess && (
            <div className="mt-2">
              <MessageBox type="success" message="Image upload complete" />
            </div>
          )}

          <ButtonTertiary
            buttonSize="sm"
            label="SWAP IMAGE"
            theme={USER_THEMES.PRIMARY}
            icon={<VscGoToFile />}
            onClick={handleSwapImageClick}
          />
        </>
      ) : (
        <ImageDropZone
          onSubmit={handleFileSave}
          loading={loadingImageUpload}
          theme={USER_THEMES.PRIMARY}
          error={error}
          setError={setError}
          currentFile={currentFile}
          setCurrentFile={setCurrentFile}
        />
      )}
    </>
  );
}
