import React, { useState } from 'react';
import {
  ButtonPrimary,
  USER_THEMES,
  InformationModal,
  Divider,
} from '@estendio/presentpal-ui-kit';
import { MdPresentToAll } from 'react-icons/md';
import Image1 from '../../../assets/presentingModal/step1.png';
import Image2 from '../../../assets/presentingModal/step2.png';
import Image3 from '../../../assets/presentingModal/step3.png';
import Image4 from '../../../assets/presentingModal/step4.png';
import PresentModalFooter from './PresentModalFooter';
import { useAmplitude } from '../../../amplitude/AmplitudeService';
import { EventType, PresentButtonEvent } from '../../../amplitude/Events';
import { logAmplitudeEvent } from '../../../amplitude/logAmplitudeEvent';

export interface PresentModalListItem {
  text: string;
  image: string;
}
export default function PresentModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const amplitude = useAmplitude();

  const logPresentButton = () => {
    const eventType = EventType.PresentButton;
    const event: PresentButtonEvent = {
      name: eventType,
      properties: {},
    };

    logAmplitudeEvent(amplitude, EventType.PresentButton, event);
  };

  const handleModalOpening = () => {
    logPresentButton();
    setIsModalOpen(true);
  };
  const handleModalClosing = () => setIsModalOpen(false);

  const presentationItems: PresentModalListItem[] = [
    {
      text:
        'In PowerPoint, start your slideshow by clicking “Slideshow” then “From Beginning”',
      image: Image1,
    },
    {
      text: 'On the Present Pal app, select the deck you wish to present with',
      image: Image2,
    },
    {
      text: 'Tap Present, and then “With slideshow”',
      image: Image3,
    },
    {
      text:
        'Select “PowerPoint on PC” or “PowerPoint on Mac” to start presenting',
      image: Image4,
    },
  ];

  const items = presentationItems.map((item, index, array) => {
    return (
      <section key={item.text}>
        <div className="flex">
          <p className="text-sm pr-1">{index + 1}&#41;</p>
          <div className="flex flex-1 flex-col space-y-4">
            <p className="text-sm">{item.text}</p>
            <img src={item.image} alt="" className="w-full pr-3" />
          </div>
        </div>
        {index < array.length - 1 && <Divider theme={USER_THEMES.PRIMARY} />}
      </section>
    );
  });

  return (
    <>
      <ButtonPrimary
        icon={<MdPresentToAll />}
        buttonSize="sm"
        label="Present"
        theme={USER_THEMES.PRIMARY}
        onClick={handleModalOpening}
      />
      <InformationModal
        isOpen={isModalOpen}
        heading="Presenting using the Present Pal add-in"
        onClose={handleModalClosing}
        footer={<PresentModalFooter />}
      >
        {items}
      </InformationModal>
    </>
  );
}
