import React, { useCallback, useContext, useEffect, useState } from 'react';
import List from '../../components/editDeck/List';
import PageWithNav from '../../components/pageTemplates/PageWithNav';
import { Routes } from '../../components/routing/types';
import { RepositoryContext } from '../../context/RepositoriesContext';
import {
  DECK_TITLE_COUNTDOWN_START,
  getInitialDeckTitleValue,
  MAX_LENGTH_DECK_TITLE,
} from '../../helpers/editDeck/editDeck';
import {
  UNTITLED_PLACEHOLDER,
  getFinalTitleValue,
} from '../../helpers/InputHelper';

import useNavigation from '../../hooks/navigationHook';
import { selectDeck, useTypedSelector } from '@estendio/presentpal-store';
import { EditDeckProps } from './types';
import {
  Header,
  TitleInput,
  ButtonPrimary,
  USER_THEMES,
  MessageBox,
} from '@estendio/presentpal-ui-kit';
import { MdAdd } from 'react-icons/md';
import EditDeckMenu from '../../components/editDeck/EditDeckMenu';
import {
  EventType,
  PageViewEvent,
  TrackedPage,
  CreateFlashcardEvent,
} from '../../amplitude/Events';
import { logAmplitudeEvent } from '../../amplitude/logAmplitudeEvent';
import { useAmplitude } from '../../amplitude/AmplitudeService';
import CloudStatusByDeck from '../../components/shared/cloudStatus/CloudStatusByDeck';
import {
  AllDeckRepoCommands,
  ICommandAction,
  IMessage,
  ITarget,
} from '@estendio/presentpal-rdm';

const EditDeck: React.FC<EditDeckProps> = ({ deckId, duplicated }) => {
  const navigateTo = useNavigation();
  const deck = useTypedSelector(selectDeck(deckId));
  const { deckRepository } = useContext(RepositoryContext);
  const title = getInitialDeckTitleValue(deck);
  const [titleInput, setTitleInput] = useState<string>(title);
  const amplitude = useAmplitude();
  const messageManager = deckRepository.myMessageManager;

  useEffect(() => {
    const messageHandler = (message: IMessage<AllDeckRepoCommands>) => {
      if (
        message.target === ITarget.Deck &&
        message.command.action === ICommandAction.Delete &&
        message.command.targetId === deckId
      ) {
        navigateTo(Routes.DeckList, undefined);
      }
    };
    if (messageManager) {
      messageManager.onDeckChange(messageHandler);
    }
  }, [deckId, messageManager, navigateTo]);

  useEffect(() => {
    const event: PageViewEvent = {
      name: EventType.PageView,
      properties: {
        page: TrackedPage.EditDeck,
      },
    };
    logAmplitudeEvent(amplitude, EventType.PageView, event);
  }, [amplitude]);

  useEffect(() => {
    //Needed to update the input title when duplicating teh deck
    setTitleInput(title);
  }, [title]);

  const logCreateFlashcard = (index: number) => {
    const event: CreateFlashcardEvent = {
      name: EventType.CreateFlashcard,
      properties: {
        index: index,
      },
    };
    logAmplitudeEvent(amplitude, EventType.CreateFlashcard, event);
  };

  const handleUpdateDeck = useCallback(
    (deckIdToUpdate: string, title: string) => {
      deckRepository?.updateDeck(deckIdToUpdate, {
        title: getFinalTitleValue(title),
      });
    },
    [deckRepository],
  );

  /**
   * If adding title to brand new deck: create the deck, setDeckId, add new title
   * Else, user is updating an existing deck
   * @param titleValue
   * @returns
   */
  const handleTitleChange = useCallback(() => {
    if (titleInput === deck?.title) {
      return;
    }
    if (deckId) {
      handleUpdateDeck(deckId, titleInput);
    }
  }, [deck, deckId, handleUpdateDeck, titleInput]);

  const handleAddFlashcard = () => {
    logCreateFlashcard(deck?.flashcards.length || 0);
    if (deckId) {
      deckRepository?.createFlashCard(deckId, UNTITLED_PLACEHOLDER);
    }
  };

  const handleSetRouteBack = () => {
    // TODO: Warning! - Hacky fix but fixes the issue short term.
    // ----------------------------------------
    // A Similar issue to PPN-3582 here with fetchDecks being called before everything has updated in the backend.
    // Put a small timeout on the navigate back to DeckList which will account for users who return back while still editing deck title
    // and not trigger onBlur which would fire an update.
    setTimeout(() => {
      navigateTo(Routes.DeckList, undefined);
    }, 1000);
  };

  const handleSetRouteToEditFlashcard = (
    flashcardId: string,
    flashcardIndex: number,
  ) => {
    handleTitleChange(); // workaround as titleInput's onBlur isnt called when a FC is selected for some reason
    navigateTo(Routes.EditFlashcard, {
      deckId,
      flashcardId,
      flashcardIndex,
      stopAutoSync: true,
    });
  };

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    if (val.length <= MAX_LENGTH_DECK_TITLE) {
      setTitleInput(e.currentTarget.value);
    }
  };

  return (
    <PageWithNav
      navOnClick={handleSetRouteBack}
      navLabel="Edit Deck"
      statusIndicator={<CloudStatusByDeck deckId={deckId} />}
      rightSideContent={
        <abbr title="Flashcard menu">
          <EditDeckMenu deckId={deckId} deckTitle={deck?.title} />
        </abbr>
      }
    >
      {duplicated && (
        <div className="pb-4 -mt-3">
          <MessageBox
            message="Deck duplicated"
            subtext="Your duplicated deck can be edited below."
            type="success"
          />
        </div>
      )}

      <div>
        <TitleInput
          placeholder={'Enter a title'}
          id="deckTitle"
          onChange={onTitleChange}
          onSubmit={handleTitleChange}
          onBlur={handleTitleChange}
          currentLength={titleInput.length}
          maxLength={MAX_LENGTH_DECK_TITLE}
          countdownFrom={DECK_TITLE_COUNTDOWN_START}
          label={'Deck title'}
          value={titleInput}
        />
      </div>
      <div className="pt-8 pb-2">
        <Header tag="h2" size="sm">
          Flashcards
        </Header>
      </div>
      {deckId && deck && deck.flashcards && (
        <List
          deckId={deckId}
          flashcards={deck.flashcards}
          handleSetRouteToEditFlashcard={handleSetRouteToEditFlashcard}
        />
      )}
      <ButtonPrimary
        fullWidth
        buttonSize="lg"
        onClick={handleAddFlashcard}
        theme={USER_THEMES.PRIMARY}
        label="ADD FLASHCARD"
        icon={<MdAdd />}
      />
    </PageWithNav>
  );
};

export default EditDeck;
