import React from 'react';
import {
  Placeholder,
  ButtonPrimary,
  USER_THEMES,
} from '@estendio/presentpal-ui-kit';
import NoDecks from '../../assets/NoDecksPlaceholder.svg';
import { MdAdd } from 'react-icons/md';

export default function EmptyList({
  handleAddDeck,
}: {
  handleAddDeck: () => void;
}) {
  return (
    <div className="flex-1 flex items-center justify-center w-full">
      <div className="space-y-6">
        <Placeholder
          imageSrc={NoDecks}
          imagePlacement="top"
          headerText="Start by creating a deck"
          headerHtmlTag="h2"
          subtitle="Decks are collections of flashcards, that make up your presentation."
        />
        <ButtonPrimary
          fullWidth
          buttonSize="lg"
          onClick={handleAddDeck}
          theme={USER_THEMES.PRIMARY}
          label="CREATE A DECK"
          icon={<MdAdd />}
        />
      </div>
    </div>
  );
}
