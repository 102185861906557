import React, { useContext, useState } from 'react';
import PageWithNav from '../../components/pageTemplates/PageWithNav';
import { Routes } from '../../components/routing/types';
import useNavigation from '../../hooks/navigationHook';
import { SafetyNetProps } from './types';
import { Header, Card, Bullet, USER_THEMES } from '@estendio/presentpal-ui-kit';
import Content from '../../components/safetyNet/Content';
import NavDeleteButton from '../../components/shared/NavDeleteButton';
import { DeleteModal } from '../../components/shared/DeleteModal';
import { editBullet } from '../../helpers/safetyNet/safetyNet';
import { RepositoryContext } from '../../context/RepositoriesContext';
import CloudStatusByDeck from '../../components/shared/cloudStatus/CloudStatusByDeck';

const SafetyNet: React.FC<SafetyNetProps> = ({
  deckId,
  flashcardId,
  flashcardIndex,
  bullet,
}) => {
  const navigateTo = useNavigation();
  const { deckRepository } = useContext(RepositoryContext);
  const handleSetRouteBack = () => {
    navigateTo(Routes.EditFlashcard, { flashcardId, deckId, flashcardIndex });
  };
  const [deleteModalOpen, setDeleteModalOpen] = useState<null | 'safety net'>(
    null,
  );

  const deleteBtn = (
    <NavDeleteButton
      type="safety net"
      onClick={() => setDeleteModalOpen('safety net')}
    />
  );

  const handleDeleteSafetyNet = () => {
    editBullet(
      deckRepository,
      bullet.id,
      deckId,
      { hint: null, undoHint: null },
      flashcardId,
    );
    handleSetRouteBack();
  };

  return (
    <div>
      <PageWithNav
        navOnClick={handleSetRouteBack}
        navLabel="Edit Safety Net"
        rightSideContent={deleteBtn}
        statusIndicator={<CloudStatusByDeck deckId={deckId} />}
      >
        <div className="space-y-2 mb-8">
          <Header tag="h2" size="sm">
            Bullet point
          </Header>
          <Card theme={USER_THEMES.PRIMARY} bg="slate-200">
            <span className="text-slate-600 text-sm flex items-center">
              <Bullet theme={USER_THEMES.PRIMARY} bg="slate-600" />
              {bullet.content ? (
                <p className="text-left text-base font-sans rounded-lg pl-3 pr-10 ">
                  {bullet.content}
                </p>
              ) : (
                // will not be seen but needed to make the bullet size match when there is no content
                // when we swap to a placeholder this can be removed
                <p
                  className={
                    'text-left text-base font-sans rounded-lg pl-7 pr-10 invisible'
                  }
                >
                  "Empty bullet"
                </p>
              )}
            </span>
          </Card>
        </div>
        <div className="mb-3">
          <Header tag="h2" size="sm">
            Add a safety net
          </Header>
        </div>
        <p className="text-slate-700 font-sans text-base mb-4">
          Choose to add either additional notes or an image to your bullet
          point, to help support you during your presentation.
        </p>
        <Content bullet={bullet} flashcardId={flashcardId} deckId={deckId} />
        <DeleteModal
          isDeleteModalOpen={!!deleteModalOpen}
          closeDeleteModal={() => setDeleteModalOpen(null)}
          handleDelete={handleDeleteSafetyNet}
          type={'safety net'}
        />
      </PageWithNav>
    </div>
  );
};

export default SafetyNet;
