import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Routes } from '../../components/routing/types';
import { RepositoryContext } from '../../context/RepositoriesContext';
import useNavigation from '../../hooks/navigationHook';
import {
  selectDecksSummary,
  useTypedSelector,
  IDeckSummary,
} from '@estendio/presentpal-store';
import EmptyList from '../../components/deckList/EmptyList';
import { Blobs, MessageBox } from '@estendio/presentpal-ui-kit';
import Footer from '../../components/pageTemplates/footer/Footer';
import List from '../../components/deckList/List';
import Header from '../../components/deckList/Header';
import ContentWrapper from '../../components/pageTemplates/ContentWrapper';
import { useAmplitude } from '../../amplitude/AmplitudeService';
import {
  PageViewEvent,
  EventType,
  TrackedPage,
  CreateDeckEventProperties,
  CreateDeckEvent,
} from '../../amplitude/Events';
import { logAmplitudeEvent } from '../../amplitude/logAmplitudeEvent';
import { Loading } from '../loading/Loading';
import { CloudStatusAllDecks } from '../../components/shared/cloudStatus/CloudStatusAllDecks';
import { formatDistanceToNow } from 'date-fns';
import { UNTITLED_PLACEHOLDER } from '../../helpers/InputHelper';
import { useOfficeService } from '../../context/OfficeContext';

const DeckList = () => {
  const navigateTo = useNavigation();
  const amplitude = useAmplitude();
  const { deckRepository } = useContext(RepositoryContext);
  const officeService = useOfficeService();
  const user = useTypedSelector(state => {
    return state.user;
  });

  const [loadingDecks, setLoadingDecks] = useState(true);
  const decks: IDeckSummary[] = useTypedSelector(selectDecksSummary);

  const decksInfo = useTypedSelector(state => {
    return {
      activeDecks: state.decks.activeDecks,
    };
  });

  useEffect(() => {
    if (user.trackingId) {
      const event: PageViewEvent = {
        name: EventType.PageView,
        properties: {
          page: TrackedPage.Home,
        },
      };
      logAmplitudeEvent(amplitude, EventType.PageView, event);
    }
  }, [amplitude, user.trackingId]);

  const handleSetRouteToEditDeck = (deckId: string) => {
    navigateTo(Routes.EditDeck, { deckId });
  };

  const loadDecks = useCallback(() => {
    deckRepository
      ?.resolvePendingRemoteLinks()
      .then(() => deckRepository?.fetchDecks(true))
      .then(() => deckRepository?.sendChangedDecks())
      .finally(() => {
        setLoadingDecks(false);
      });
  }, [deckRepository]);

  useEffect(() => {
    loadDecks();
  }, [loadDecks]);

  const handleRefresh = () => {
    setLoadingDecks(true);
    loadDecks();
  };

  const logAddNewDeck = (properties: CreateDeckEventProperties) => {
    const event: CreateDeckEvent = {
      name: EventType.CreateDeck,
      properties,
    };
    logAmplitudeEvent(amplitude, EventType.CreateDeck, event);
  };

  const matchSlidesToFlashcards = async (deckId: string) => {
    try {
      const slidesInfo = await officeService.getAllSlidesInfo();
      if (slidesInfo) {
        deckRepository.updateFlashcardsToMatchSlides(deckId, slidesInfo);
      }
    } catch (e) {
      console.log('Error on matching flashcards to slides:', e);
    }
  };

  const [error, setError] = useState('');
  const handleAddDeck = async () => {
    try {
      setError('');
      const deck = await deckRepository?.createDeck(UNTITLED_PLACEHOLDER);
      //Amplitude event
      const logProperties: CreateDeckEventProperties = {
        deckId: deck.id,
        totalDecks: decks.length + 1,
        timePassedFromRegistration: user.licence.registered
          ? formatDistanceToNow(new Date(user.licence.registered))
          : 'unknown', //on remember me user state gets reset to init state
      };
      logAddNewDeck(logProperties);
      //Auto generate missing flashcards to match the slides
      matchSlidesToFlashcards(deck.id);
      //Navigate to the Edit Deck page
      navigateTo(Routes.EditDeck, { deckId: deck.id });
    } catch (e) {
      setError(
        'Failed to create a new deck. Refresh the add-in and try again.',
      );
    }
  };

  const renderContent = () => {
    if (loadingDecks) {
      return <Loading fullScreen={true} />;
    }
    if (decksInfo.activeDecks === 0) {
      return <EmptyList handleAddDeck={handleAddDeck} />;
    }
    const decksByDescModified = decks.sort(
      (a, b) =>
        Math.max(b.modified, b.lastUpdated) -
        Math.max(a.modified, a.lastUpdated),
    );
    return (
      <List
        decks={decksByDescModified}
        handleSetRouteToEditDeck={handleSetRouteToEditDeck}
        handleAddDeck={handleAddDeck}
        matchSlidesToFlashcards={matchSlidesToFlashcards}
      />
    );
  };

  return (
    <div className="h-screen flex flex-col text-slate-900">
      <Blobs />
      <Header
        fetchingDecksError={''} // TODO
        onRefreshPress={handleRefresh}
        statusIndicator={<CloudStatusAllDecks decks={decks} />}
      />
      {error && (
        <div className="my-2">
          <MessageBox type="error" message={error} />
        </div>
      )}
      <ContentWrapper>{renderContent()}</ContentWrapper>
      <Footer />
    </div>
  );
};

export default DeckList;
