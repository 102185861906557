import {
  IStoreDispatch,
  IThunkDispatch,
  actionChangeFlashCardIndex,
  actionCreateFlashCard,
  actionDeleteFlashCard,
  actionDeleteFlashcards,
  actionDuplicateFlashcards,
  actionInsertFlashcardAtIndex,
  actionRearrangeFlashcards,
  actionUpdateFlashCard,
} from '@estendio/presentpal-store';
import {
  IBulkDeleteFlashcardsCommand,
  IBulkDuplicateFlashcardsCommand,
  ICommandAction,
  ICreateFlashcardCommand,
  IDeleteFlashcardCommand,
  IFlashcardCommands,
  IMessage,
  IMoveFlashcardByOneIndexCommand,
  IRearrangeFlashcardsCommand,
  IUpdateFlashcardCommand,
} from '@estendio/presentpal-rdm';

function handleChangesOnFlashcardCreation(
  command: ICreateFlashcardCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) => {
    thunkDispatch((dispatch: IStoreDispatch) => {
      if (command.atIndex === undefined) {
        dispatch(actionCreateFlashCard(command.deckId, command.payload, false));
      } else {
        dispatch(
          actionInsertFlashcardAtIndex(
            command.deckId,
            command.atIndex,
            command.payload,
            false,
          ),
        );
      }
    });
  });
}

function handleChangesOnFlashcardDeletion(
  command: IDeleteFlashcardCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) =>
    dispatch(actionDeleteFlashCard(command.deckId, command.targetId)),
  );
}
function handleChangesOnFlashcardUpdate(
  command: IUpdateFlashcardCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) =>
    dispatch(
      actionUpdateFlashCard(
        command.deckId,
        command.targetId,
        command.payload,
        false,
      ),
    ),
  );
}
function handleChangesOnFlashcardBulkDelete(
  command: IBulkDeleteFlashcardsCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) =>
    dispatch(
      dispatch(actionDeleteFlashcards(command.deckId, command.targetIds)),
    ),
  );
}
function handleChangesOnFlashcardBulkDuplication(
  command: IBulkDuplicateFlashcardsCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) =>
    dispatch(
      actionDuplicateFlashcards(
        command.deckId,
        command.targetIds,
        command.newFlashcardsIds,
        command.newBulletsIds,
        false,
      ),
    ),
  );
}
function handleChangesOnFlashcardRearrange(
  command: IRearrangeFlashcardsCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) =>
    dispatch(
      dispatch(
        actionRearrangeFlashcards(command.deckId, command.targetIds, false),
      ),
    ),
  );
}
function handleChangesOnFlashcardMoveByOneIndex(
  command: IMoveFlashcardByOneIndexCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) =>
    dispatch(
      actionChangeFlashCardIndex(
        command.deckId,
        command.targetId,
        command.indexChange,
        false,
      ),
    ),
  );
}

export default function handleFlashcardChanges(
  data: IMessage<IFlashcardCommands>,
  thunkDispatch: IThunkDispatch,
) {
  const { command } = data;
  switch (command.action) {
    case ICommandAction.Create:
      handleChangesOnFlashcardCreation(command, thunkDispatch);
      break;
    case ICommandAction.Delete:
      handleChangesOnFlashcardDeletion(command, thunkDispatch);
      break;
    case ICommandAction.Update:
      handleChangesOnFlashcardUpdate(command, thunkDispatch);
      break;
    case ICommandAction.BulkDelete:
      handleChangesOnFlashcardBulkDelete(command, thunkDispatch);
      break;
    case ICommandAction.BulkDuplicate:
      handleChangesOnFlashcardBulkDuplication(command, thunkDispatch);
      break;
    case ICommandAction.RearrangeItems:
      handleChangesOnFlashcardRearrange(command, thunkDispatch);
      break;
    case ICommandAction.MoveByOneIndex:
      handleChangesOnFlashcardMoveByOneIndex(command, thunkDispatch);
      break;
  }
}
