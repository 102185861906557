import {
  MessageBox,
  IconButton,
  USER_THEMES,
  Header as H1,
} from '@estendio/presentpal-ui-kit';
import React from 'react';
import { MdRefresh } from 'react-icons/md';

export default function Header({
  fetchingDecksError,
  onRefreshPress,
  statusIndicator,
}: {
  fetchingDecksError: string;
  onRefreshPress: () => void;
  statusIndicator?: JSX.Element;
}) {
  return (
    <div className="p-5">
      {statusIndicator && statusIndicator}
      <div className="flex justify-between items-center pt-2">
        <H1 tag="h1" size="lg">
          Decks
        </H1>
        <div className="flex space-x-1">
          <abbr title="Refresh add-in">
            <IconButton
              icon={<MdRefresh />}
              ariaLabel="Refresh application"
              iconSize="lg"
              theme={USER_THEMES.PRIMARY}
              onClick={onRefreshPress}
            />
          </abbr>
        </div>
      </div>
      {fetchingDecksError && (
        <div className="pt-4">
          <MessageBox message={fetchingDecksError} type="error" />
        </div>
      )}
    </div>
  );
}
