import { IDeckSummary } from '@estendio/presentpal-store';
import { getFormattedDate } from '../DateHelper';
import { endSentenceForScreenReaders } from '../StringHelper';

export const getFlashcardsTotalText = (flashcardsTotal: number) => {
  return flashcardsTotal === 1
    ? `1 flashcard`
    : `${flashcardsTotal} flashcards`;
};

export const getDeckSubtitle = (flashcardsTotal: number, created: number) => {
  const createdAtFormatted = getFormattedDate(created);
  const flashcardsTotalText = getFlashcardsTotalText(flashcardsTotal);
  return `${flashcardsTotalText} | ${createdAtFormatted} `;
};

export const getAriaLabel = (deck: IDeckSummary) => {
  const flashcardsTotalText = getFlashcardsTotalText(deck.flashcards);
  const createdAtFormatted = getFormattedDate(deck.created);
  const sentenceEnd = endSentenceForScreenReaders(deck.title);
  return `${deck.title}. Contains ${flashcardsTotalText}${sentenceEnd} Created an ${createdAtFormatted}.`;
};
