import React from 'react';
import LogoutModal from './LogoutModal';
import PresentModal from './PresentModal';

export default function Footer() {
  return (
    <footer className="flex justify-between border-t-2 px-5 py-3">
      <PresentModal />
      <LogoutModal />
    </footer>
  );
}
