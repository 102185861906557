import { MAX_LENGTH_FC_TITLE } from './editFlashcard/editFlashcard';

export const needsPlaceholder = (currentContent: string) => {
  if (currentContent === 'Untitled' || !currentContent) {
    return true;
  }
  return false;
};

export const UNTITLED_PLACEHOLDER = 'Untitled';

export const getFinalTitleValue = (value: string) => {
  return value === '' ? UNTITLED_PLACEHOLDER : value;
};

export const getInitialTitleValue = (title: string) => {
  return !needsPlaceholder(title) ? title : '';
};

export const getTruncatedTitle = (title: string) => {
  if (title.length <= MAX_LENGTH_FC_TITLE) {
    return title;
  }
  return `${title.substring(0, MAX_LENGTH_FC_TITLE - 1)}${'\u2026'}`;
};
