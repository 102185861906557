import React, { useContext, useState } from 'react';
import { Menu, MenuItem, USER_THEMES } from '@estendio/presentpal-ui-kit';
import { AiFillCopy } from 'react-icons/ai';
import { MdDeleteForever } from 'react-icons/md';
import { RepositoryContext } from '../../context/RepositoriesContext';
import useNavigation from '../../hooks/navigationHook';
import { Routes } from '../routing/types';
import { DeleteModal } from '../shared/DeleteModal';
import { getFinalTitleValue } from '../../helpers/InputHelper';

export interface EditDeckMenuProps {
  deckId: string;
  deckTitle: string | undefined;
}
export default function EditDeckMenu({
  deckId,
  deckTitle = '',
}: EditDeckMenuProps) {
  const theme = USER_THEMES.PRIMARY;
  const navigateTo = useNavigation();

  const { deckRepository } = useContext(RepositoryContext);

  const [isDeleteDeckModalOpen, setIsDeleteDeckModalOpen] = useState(false);
  const openDeleteDeckModal = () => {
    setIsDeleteDeckModalOpen(true);
  };
  const closeDeleteDeckModal = () => {
    setIsDeleteDeckModalOpen(false);
  };
  const navigateToDeckList = () => {
    navigateTo(Routes.DeckList, undefined);
  };
  const handleDeckDelete = () => {
    if (!deckId) {
      navigateToDeckList();
    }
    deckRepository.deleteDeck(deckId).then(_ => {
      /**
       * Why there is a setTimeout here
       * ------------------------------
       * Bug fix for PPN-3582, deleting a deck caused a recovered deck to show list
       * once user is navigated back to DeckList.
       * Looking at the response from updateDeckHandler it looks like the we're updating
       * the deleted property to true. However when fetchDecks is called after user is
       * navigated back to DeckList, dynamodb is not returning the most up to date
       * data which reflects the recent deleted property update (delete is still set to false,
       * causing a conflict, which in turn causes a recovered deck to be generated).
       * Dynamodb has an option to force the latest data to be returned, ConsistentRead.
       * We tried adding this to the list function called in getDecksHandler, but did not fix the problem.
       * As a temporary patch, added a set timeout so that by the time fetchDecks runs
       * it fetches the correct deleted=true property for the recently deleted deck
       */

      setTimeout(navigateToDeckList, 750);
    });
  };

  const [processing, setProcessing] = useState(false);
  const handleDeckDuplication = async () => {
    if (processing) return;
    setProcessing(true);
    const prefix = 'Copy of ';
    const newTitle = prefix + getFinalTitleValue(deckTitle);
    const duplicatedDeck = await deckRepository.duplicateDeck(deckId, newTitle);
    navigateTo(Routes.EditDeck, {
      deckId: duplicatedDeck.id,
      duplicated: true,
    });
    //Prevents users from creating multiple duplicates on vigorous clicking
    setTimeout(() => {
      setProcessing(false);
    }, 1000);
  };
  return (
    <>
      <Menu theme={theme} buttonIconSize="xl">
        <MenuItem
          icon={
            <span className="rotate-180">
              <AiFillCopy />
            </span>
          }
          theme={theme}
          text="Duplicate deck"
          role="button"
          onClick={handleDeckDuplication}
        />
        <MenuItem
          icon={<MdDeleteForever />}
          theme={theme}
          text="Delete deck"
          role="button"
          onClick={openDeleteDeckModal}
        />
      </Menu>
      <DeleteModal
        type="deck"
        isDeleteModalOpen={isDeleteDeckModalOpen}
        handleDelete={handleDeckDelete}
        closeDeleteModal={closeDeleteDeckModal}
      />
    </>
  );
}
