import { IBullet, IFlashCard } from '@estendio/presentpal-store';
import { getInitialTitleValue } from '../InputHelper';

export const getInitialFlashcardTitleValue = (flashcard: IFlashCard | null) => {
  return flashcard && flashcard.title
    ? getInitialTitleValue(flashcard.title)
    : '';
};

export const getInitialBulletValue = (bullet: IBullet | null) => {
  return bullet && bullet.content ? getInitialTitleValue(bullet.content) : '';
};

export const MAX_LENGTH_BULLET = 150;
export const MAX_LENGTH_FC_TITLE = 50;
export const BULLET_CHARS_COUNTDOWN_START = 130;
export const FC_TITLE_CHARS_COUNTDOWN_START = 40;
