import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RepositoryContext } from '../../context/RepositoriesContext';
import { rememberMeSignInWithChecks } from '../../helpers/login/login';
import { Blobs } from '@estendio/presentpal-ui-kit';
import { Loading } from '../loading/Loading';
import { Routes } from '../../components/routing/types';
import { useAmplitude } from '../../amplitude/AmplitudeService';
import { LoginEvent, EventType, EventSuccess } from '../../amplitude/Events';
import { logAmplitudeEvent } from '../../amplitude/logAmplitudeEvent';
import { logout } from '../../helpers/logout/logout';
import { RouteContext } from '../../context/RouteContext';
import LoginForm from './LoginForm';

export interface Errors {
  passwordError: string;
  emailError: string;
  genericError: string;
}

export const defaultErrors = {
  passwordError: '',
  emailError: '',
  genericError: '',
};

function AuthWrapper({ withLoginForm }: { withLoginForm?: boolean }) {
  const { userRepository } = useContext(RepositoryContext);
  const dispatch = useDispatch();
  const { setRoute } = useContext(RouteContext);
  const [errors, setErrors] = useState<Errors>(defaultErrors);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoggedInViaRememberMe, setIsLoggedInViaRememberMe] = useState<
    boolean | null
  >(null);

  const amplitude = useAmplitude();

  const logSignIn = useCallback(
    (success: EventSuccess, error: string | null, viaRememberMe: boolean) => {
      const event: LoginEvent = {
        name: EventType.Login,
        properties: {
          success: success,
          error: error,
          rememberMe: viaRememberMe,
        },
      };
      logAmplitudeEvent(amplitude, EventType.Login, event);
    },
    [amplitude],
  );

  const handleExpired = useCallback(() => {
    logSignIn(EventSuccess.False, 'Expired licence', false);
    setLoading(false);
    setIsLoggedInViaRememberMe(false);
    setErrors({ ...errors, genericError: 'Licence expired' });
    logout(userRepository, dispatch);
  }, [dispatch, errors, logSignIn, userRepository]);

  const handleFailedRememberMe = useCallback(() => {
    setIsLoggedInViaRememberMe(false);
    logout(userRepository, dispatch);
  }, [dispatch, userRepository]);

  const handleSuccessRememberMe = useCallback(() => {
    logSignIn(EventSuccess.True, null, true);
    setRoute(Routes.DeckList, undefined);
  }, [logSignIn, setRoute]);

  // HANDLES REMEMBER ME
  useEffect(() => {
    if (isLoggedInViaRememberMe !== null) {
      return;
    }
    userRepository.isLoggedIn().then(res => {
      if (res) {
        // user is logged in via remember me, but lets fetch their details from portal first
        // we'll check for expired licences, and fill in any missing info from portal
        rememberMeSignInWithChecks(
          userRepository,
          handleExpired,
          dispatch,
          handleFailedRememberMe,
          handleSuccessRememberMe,
        );
      } else {
        handleFailedRememberMe();
      }
    });
  }, [
    dispatch,
    handleExpired,
    handleFailedRememberMe,
    handleSuccessRememberMe,
    isLoggedInViaRememberMe,
    userRepository,
  ]);

  const checkingRememberMe =
    isLoggedInViaRememberMe || isLoggedInViaRememberMe === null; // will be null whilst useEffect is checking details, so show loading

  const showLoadingSpinner = loading || checkingRememberMe;
  const content = withLoginForm ? (
    <LoginForm
      errors={errors}
      setErrors={setErrors}
      setLoading={setLoading}
      handleExpired={handleExpired}
      logSignIn={logSignIn}
    />
  ) : null;

  return (
    <div>
      <Blobs />
      <div className="p-5">
        {showLoadingSpinner ? <Loading fullScreen /> : content}
      </div>
    </div>
  );
}

export default AuthWrapper;
