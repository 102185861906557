const accessCodeRegEx = /^[A-Z]{8}$/;

export default class Validator {
  public static isValidEmail(email?: string | null): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  public static isValidAccessCode(accessCode?: string | null): boolean {
    return !!(accessCode && accessCodeRegEx.test(accessCode));
  }

  public static isValidPassword(password?: string): boolean {
    return password != null && password.length > 0;
  }

  public static isValidPasswordv3(password?: string | null): boolean {
    return password != null && password.length > 0;
  }

  public static isValidURL(url: string): boolean {
    const res = url.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
    );
    return res !== null;
  }

  public static isValidName(name: string | null): boolean {
    return name !== null && name.length > 0;
  }

  public static passwordsMatch(
    password: string | null,
    confirmedPassword: string | null,
  ): boolean {
    return password === confirmedPassword ? true : false;
  }
}
