import {
  IStoreDispatch,
  IThunkDispatch,
  actionChangeBulletIndex,
  actionCreateBullet,
  actionDeleteBullet,
  actionDeleteBullets,
  actionDuplicateBullets,
  actionRearrangeBullets,
  actionUpdateBullet,
} from '@estendio/presentpal-store';
import {
  IBulkDeleteBulletsCommand,
  IBulkDuplicateBulletsCommand,
  IBulletCommands,
  ICommandAction,
  ICreateBulletCommand,
  IDeleteBulletCommand,
  IMessage,
  IMoveBulletByOneIndexCommand,
  IRearrangeBulletsCommand,
  IUpdateBulletCommand,
} from '@estendio/presentpal-rdm';

function handleChangesOnBulletCreation(
  command: ICreateBulletCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) =>
    dispatch(
      actionCreateBullet(
        command.deckId,
        command.flashcardId,
        command.payload,
        false,
      ),
    ),
  );
}
function handleChangesOnBulletDeletion(
  command: IDeleteBulletCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) =>
    dispatch(
      actionDeleteBullet(command.deckId, command.flashcardId, command.targetId),
    ),
  );
}
function handleChangesOnBulletUpdate(
  command: IUpdateBulletCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) =>
    dispatch(
      actionUpdateBullet(
        command.deckId,
        command.flashcardId,
        command.targetId,
        command.payload,
        false,
      ),
    ),
  );
}
function handleChangesOnBulletBulkDeletion(
  command: IBulkDeleteBulletsCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) =>
    dispatch(
      actionDeleteBullets(
        command.deckId,
        command.flashcardId,
        command.targetIds,
      ),
    ),
  );
}
function handleChangesOnBulletBulkDuplication(
  command: IBulkDuplicateBulletsCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) =>
    dispatch(
      actionDuplicateBullets(
        command.deckId,
        command.flashcardId,
        command.targetIds,
        command.newIds,
        false,
      ),
    ),
  );
}
function handleChangesOnBulletRearrange(
  command: IRearrangeBulletsCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) =>
    dispatch(
      actionRearrangeBullets(
        command.deckId,
        command.flashcardId,
        command.targetIds,
        false,
      ),
    ),
  );
}
function handleChangesOnBulletMoveByOneIndex(
  command: IMoveBulletByOneIndexCommand,
  thunkDispatch: IThunkDispatch,
) {
  thunkDispatch((dispatch: IStoreDispatch) =>
    dispatch(
      actionChangeBulletIndex(
        command.deckId,
        command.flashcardId,
        command.targetId,
        command.indexChange,
        false,
      ),
    ),
  );
}

function handleBulletChanges(
  data: IMessage<IBulletCommands>,
  thunkDispatch: IThunkDispatch,
) {
  const { command } = data;
  switch (command.action) {
    case ICommandAction.Create:
      handleChangesOnBulletCreation(command, thunkDispatch);
      break;
    case ICommandAction.Delete:
      handleChangesOnBulletDeletion(command, thunkDispatch);
      break;
    case ICommandAction.Update:
      handleChangesOnBulletUpdate(command, thunkDispatch);
      break;
    case ICommandAction.BulkDelete:
      handleChangesOnBulletBulkDeletion(command, thunkDispatch);
      break;
    case ICommandAction.BulkDuplicate:
      handleChangesOnBulletBulkDuplication(command, thunkDispatch);
      break;
    case ICommandAction.RearrangeItems:
      handleChangesOnBulletRearrange(command, thunkDispatch);
      break;
    case ICommandAction.MoveByOneIndex:
      handleChangesOnBulletMoveByOneIndex(command, thunkDispatch);
      break;
  }
}

export default handleBulletChanges;
