import React, { FC } from 'react';
import { useTypedSelector } from '@estendio/presentpal-store';
import CloudStatusIcon from './CloudStatusIcon';

interface ByDeckCloudStatusProps {
  deckId: string;
}

const CloudStatusByDeck: FC<ByDeckCloudStatusProps> = ({ deckId }) => {
  const status = useTypedSelector(state => state.status);
  const currentDeckStatus =
    status.byDeckId[deckId] && status.byDeckId[deckId].status
      ? status.byDeckId[deckId].status
      : 'sent';

  return <CloudStatusIcon status={currentDeckStatus} />;
};

export default CloudStatusByDeck;
