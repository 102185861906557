import React, { FC } from 'react';
import {
  MdOutlineCloudDone,
  MdOutlineCloudOff,
  MdOutlineCloudUpload,
} from 'react-icons/md';

import { deckStatus } from '@estendio/presentpal-store';
import { ConnectionErrorMessage } from '../ConnectionErrorMessage';

interface CloudStatusIconProps {
  status: deckStatus;
}

const CloudStatusIcon: FC<CloudStatusIconProps> = ({ status }) => {
  let icon;
  const iconSize = 24;
  switch (status) {
    case 'failed':
      icon = <MdOutlineCloudOff size={iconSize} className="text-slate-900" />;
      break;
    case 'sending':
      icon = (
        <MdOutlineCloudUpload size={iconSize} className="text-slate-900" />
      );
      break;
    default:
      icon = (
        <MdOutlineCloudDone size={iconSize} className="text-emerald-600" />
      );
  }
  const statusLabel = `Cloud syncing: ${status}`;
  return (
    <abbr title={statusLabel}>
      <span role="status" aria-label={statusLabel}>
        {icon}
      </span>
      <ConnectionErrorMessage />
    </abbr>
  );
};

export default CloudStatusIcon;
