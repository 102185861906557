import React, { useState, useContext } from 'react';
import {
  Input,
  ButtonPrimary,
  USER_THEMES,
  MessageBox,
  HeroImage,
  Checkbox,
  LinkAsNav,
} from '@estendio/presentpal-ui-kit';
import Logo from '../../assets/present-pal-logo.svg';
import { MdLogin } from 'react-icons/md';
import useNavigation from '../../hooks/navigationHook';
import { checkEmail, checkPassword, login } from '../../helpers/login/login';
import { Routes } from '../../components/routing/types';
import { Errors, defaultErrors } from './AuthWrapper';
import { RepositoryContext } from '../../context/RepositoriesContext';
import { useDispatch } from 'react-redux';
import { RouteContext } from '../../context/RouteContext';
import { EventSuccess } from '../../amplitude/Events';

export default function LoginForm({
  setErrors,
  errors,
  setLoading,
  handleExpired,
  logSignIn,
}: {
  errors: Errors;
  setErrors: (errors: Errors) => void;
  setLoading: (loading: boolean) => void;
  handleExpired: () => void;
  logSignIn: (
    success: EventSuccess,
    error: string | null,
    viaRememberMe: boolean,
  ) => void;
}) {
  const { userRepository } = useContext(RepositoryContext);
  const dispatch = useDispatch();
  const navigateTo = useNavigation();
  const { setRoute } = useContext(RouteContext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [validPassword, setValidPassword] = useState<boolean>(false);

  const handleForgotPassword = () => {
    navigateTo(Routes.ForgotPassword, { loginEmail: email });
  };
  const validateEmail = (value: string) => {
    const emailErrorCheck = checkEmail(value);
    if (emailErrorCheck.error) {
      setErrors({
        ...errors,
        emailError: emailErrorCheck.error,
      });
    } else {
      setValidEmail(true);
    }
  };
  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    handleLogin();
  };

  const handleOnClick = () => {
    handleLogin();
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
    setValidEmail(false);
  };

  // validates and logs in
  const handleLogin = () => {
    setLoading(true);
    setErrors({ ...errors, ...defaultErrors });
    const emailRes = checkEmail(email);
    const passwordRes = checkPassword(password);
    if (emailRes.error || passwordRes.error) {
      setErrors({
        ...errors,
        emailError: emailRes.error,
        passwordError: passwordRes.error,
      });
      setLoading(false);
    }

    if (!emailRes.error && !passwordRes.error) {
      // no validation errors, so we can try and login
      login(
        userRepository,
        dispatch,
        emailRes.trimmedInput,
        password,
        rememberMe,
        handleExpired,
        onSuccess,
        onFailure,
      );
    }
  };

  const onSuccess = () => {
    logSignIn(EventSuccess.True, null, false);
    setRoute(Routes.DeckList, undefined);
  };

  const onFailure = () => {
    logSignIn(EventSuccess.False, 'add in login failure', false);
    setLoading(false);
    setErrors({
      ...errors,
      genericError:
        'Login failed. Please ensure that you are using your PresentPal registered email.',
    });
  };
  return (
    <>
      <div className="mt-4 mb-12 h-48 animate-fadeIn">
        <HeroImage src={Logo} height="max-h-48" alt="Present Pal Logo" />
      </div>
      <form onSubmit={handleOnSubmit}>
        <div className="mb-4">
          <Input
            onChange={onEmailChange}
            onFocus={() => {
              setErrors({ ...errors, emailError: '', genericError: '' });
              setValidEmail(false);
            }}
            type="text"
            id="email"
            error={errors.emailError}
            label="Email address"
            helpText="Example: yourname@email.com"
            isValid={validEmail}
            value={email}
            onBlur={e => validateEmail(e.currentTarget.value)}
          />
        </div>
        <div className="mb-1">
          <Input
            onChange={e => setPassword(e.currentTarget.value)}
            onFocus={() => {
              setErrors({
                ...errors,
                passwordError: '',
                genericError: '',
              });
              setValidPassword(false);
            }}
            onBlur={() => {
              const passwordCheck = checkPassword(password);
              if (passwordCheck.error) {
                setErrors({
                  ...errors,
                  passwordError: passwordCheck.error,
                });
                setValidPassword(false);
              } else {
                setValidPassword(true);
              }
            }}
            type="password"
            isValid={validPassword}
            value={password}
            label="Password"
            error={errors.passwordError}
            id="password"
          />
        </div>
        <LinkAsNav
          theme={USER_THEMES.PRIMARY}
          onClick={() => handleForgotPassword()}
          text="Forgot password"
        />
        <div className="my-2">
          <Checkbox
            checked={rememberMe}
            id={'rememberMe'}
            label="Remember me"
            theme={USER_THEMES.PRIMARY}
            onChange={() => setRememberMe(!rememberMe)}
          />
        </div>
        {errors.genericError ? (
          <MessageBox message={errors.genericError} type="error" />
        ) : null}
        <div className="my-4">
          <ButtonPrimary
            label="Login"
            fullWidth
            onClick={handleOnClick}
            theme={USER_THEMES.PRIMARY}
            icon={<MdLogin />}
          />
        </div>
      </form>
    </>
  );
}
